import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { setupListeners } from '@reduxjs/toolkit/query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import App from './App';
import { initSession } from './app/session';
import { setupStore } from './app/store';
import { loggedIn } from './features/auth/authSlice';
import router from './router';

function prepare() {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');
    return worker.start();
  }

  return Promise.resolve();
}

const store = setupStore();

// Required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);
initSession(() => {
  store.dispatch(loggedIn());
});

prepare().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <FluentProvider theme={webLightTheme}>
          <IntlProvider locale={navigator.language} defaultLocale="ru-RU">
            <App>
              <RouterProvider router={router} />
            </App>
          </IntlProvider>
        </FluentProvider>
      </Provider>
    </React.StrictMode>
  );
});
