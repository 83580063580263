import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CBO_API_BASE_URL } from '../../../config';
import { reauth, signWithToken } from '../../utils/auth';
import { retry } from '../../utils/retry';

const baseQuery = reauth(
  retry(
    fetchBaseQuery({
      baseUrl: CBO_API_BASE_URL,
      prepareHeaders: signWithToken,
    })
  )
);

export const baseApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
