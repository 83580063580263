import { AppDispatch } from '../../store';
import {
  AttributionModel,
  Campaign,
  CampaignItem,
  Constraint,
  DateBasedConstraints,
  enhancedApi,
  Forecast,
  ForecastCampaignItem,
  ForecastErrorCode,
  ForecastGroupItem,
  ForecastItem,
  ForecastStatus,
  GetForecastsApiResponse,
  GetProjectSettings,
  GetProjectSettingsApiResponse,
  Goal,
  GoalItem,
  GroupItem,
  OptimizationStrategy,
  Statistics,
  SyncCounter,
  UpdateProjectSettings,
  UpdateProjectSettingsApiArg,
  UpdateSharedAccountRequest,
} from './api.generated';

export * from './translate';
export type {
  AttributionModel,
  Campaign,
  CampaignItem,
  Constraint,
  DateBasedConstraints,
  Forecast,
  ForecastCampaignItem,
  ForecastErrorCode,
  ForecastItem,
  ForecastGroupItem,
  ForecastStatus,
  GetForecastsApiResponse,
  GetProjectSettings,
  GetProjectSettingsApiResponse,
  Goal,
  GoalItem,
  GroupItem,
  OptimizationStrategy,
  Statistics,
  SyncCounter,
  UpdateProjectSettings,
  UpdateProjectSettingsApiArg,
};

export type UpdateProjectSettingsReason =
  | 'avg_order_value_required'
  | 'campaign_not_found'
  | 'constraint_conflicts'
  | 'constraint_duplication_detected'
  | 'delete_campaigns_prohibited'
  | 'goal_duplication_detected'
  | 'goal_not_found'
  | 'version_mismatch'
  | 'yandex_api_unavailable';

export type CampaignRejectionStatus = ForecastCampaignItem['rejectionStatus'];
export type ConstraintLabel = Constraint['label'];
export type UpdateSharedAccountAction = UpdateSharedAccountRequest['data']['action'];

export const optimizerApi = enhancedApi.enhanceEndpoints({
  addTagTypes: ['Settings'],
  endpoints: {
    getProjectSettings: {
      providesTags: (result, error, arg) => [{ type: 'Settings', id: arg.project }],
    },
    updateProjectSettings: {
      invalidatesTags: (result, error, arg) => (error ? [] : [{ type: 'Settings', id: arg.project }]),
      extraOptions: {
        maxRetries: 0,
      },
    },
    suspend: {
      invalidatesTags: (result, error, arg) => [{ type: 'Settings', id: arg.project }],
    },
    unsuspend: {
      invalidatesTags: (result, error, arg) => [{ type: 'Settings', id: arg.project }],
    },
    updateSharedAccount: {
      invalidatesTags: (result, error, arg) => (error ? [] : [{ type: 'Settings', id: arg.project }]),
      extraOptions: {
        maxRetries: 0,
      },
    },
  },
});

export const {
  useGetProjectSettingsQuery,
  useUpdateProjectSettingsMutation,
  useGetCampaignsQuery,
  useGetGoalsQuery,
  useGetForecastsQuery,
  useGetForecastQuery,
  useSuspendMutation,
  useUnsuspendMutation,
  useUpdateSharedAccountMutation,
} = optimizerApi;

export async function getForecast(dispatch: AppDispatch, projectId: string, taskId: number): Promise<Forecast> {
  const promise = dispatch(
    enhancedApi.endpoints.getForecast.initiate({
      project: projectId,
      forecast: taskId,
    })
  );
  const res = await promise;
  promise.unsubscribe();

  if (res.isSuccess) {
    return res.data.data;
  }

  return Promise.reject('Could not load task');
}
