import { Input, InputProps } from '@fluentui/react-components';
import { DismissRegular, SearchRegular } from '@fluentui/react-icons';
import { ChangeEvent, useCallback, useRef } from 'react';

export type SearchInputProps = Omit<InputProps, 'type' | 'contentBefore' | 'contentAfter'>;

export type InputChangeEventHandler = NonNullable<InputProps['onChange']>;

export const SearchInput = ({ appearance = 'underline', value, onChange, ...rest }: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClearClick = useCallback(() => {
    const { current } = inputRef;
    if (current && current.value !== '') {
      current.value = '';

      if (onChange) {
        const event = {
          target: current,
        } as ChangeEvent<HTMLInputElement>;
        onChange(event, { value: '' });
      }
    }
  }, [inputRef, onChange]);

  return (
    <Input
      ref={inputRef}
      appearance={appearance}
      contentBefore={<SearchRegular />}
      contentAfter={value ? <DismissRegular onClick={handleClearClick} /> : undefined}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SearchInput;
