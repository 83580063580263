import { Navigate, useLocation } from 'react-router-dom';
import { NotFound } from '../components/error';

const pathRe = /^\/[0-9a-f]{8}-[0-9a-f]{4}-[0-8][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\/?/i;

export const OldRoutes = () => {
  const { pathname } = useLocation();

  if (pathRe.test(pathname)) {
    return <Navigate to={`/p${pathname}`} />;
  }

  return <NotFound />;
};

export default OldRoutes;
