import { Link, makeStyles, shorthands, typographyStyles } from '@fluentui/react-components';
import ContentContainer from '../components/ContentContainer';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    '& h1': {
      ...typographyStyles.title2,
    },
    '& p': {
      ...shorthands.margin('0.5em', '0px'),
    },
  },
});

export const Index = () => {
  const classes = useStyles();

  return (
    <ContentContainer className={classes.root} size="large">
      <h1>Выбор проекта</h1>
      <p>Вероятно, у Вас нет доступа ни к одному проекту, если видите эту страницу.</p>
      <p>
        Можно{' '}
        <Link inline href="https://projects.airmediapro.ru">
          создать
        </Link>{' '}
        новый проект прямо сейчас.
      </p>
    </ContentContainer>
  );
};

export default Index;
