import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  mergeClasses,
  TableColumnDefinition,
  TableColumnId,
} from '@fluentui/react-components';
import { useMemo } from 'react';
import { Forecast, useGetCampaignsQuery } from '../../../app/services/optimizer';
import { currencyOptions, integerOptions } from '../../../utils/intl';
import { SyncStat } from '../CounterStat';
import { buildCampaignListItems, CampaignItem } from '../data';
import MetricValue from '../MetricValue';
import NoGroup from '../NoGroup';

export type CampaignsPanelProps = {
  className?: string;
  projectId: string;
  task: Forecast;
};

const columns: TableColumnDefinition<CampaignItem>[] = [
  createTableColumn({
    columnId: 'id',
    compare: (a, b) => a.id - b.id,
    renderHeaderCell: () => 'ID',
    renderCell: (item) => item.id,
  }),
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => a.name.localeCompare(b.name),
    renderHeaderCell: () => 'Название',
    renderCell: (item) => item.name,
  }),
  createTableColumn({
    columnId: 'group',
    compare: (a, b) => (a.group?.name || '').localeCompare(b.group?.name || ''),
    renderHeaderCell: () => 'Группа',
    renderCell: (item) => {
      if (item.group) {
        return item.group.name;
      }

      return <NoGroup />;
    },
  }),
  createTableColumn({
    columnId: 'rejectionStatus',
    compare: (a, b) => a.rejectionStatus.localeCompare(b.rejectionStatus),
    renderHeaderCell: () => 'Причина исключения',
    renderCell: (item) => item.rejectionStatus,
  }),
  createTableColumn({
    columnId: 'bids',
    compare: (a, b) => a.bidCounts.total - b.bidCounts.total,
    renderHeaderCell: () => 'Ставки',
    renderCell: (item) => <SyncStat counter={item.bidCounts} />,
  }),
  createTableColumn({
    columnId: 'bidModifiers',
    compare: (a, b) => a.bidModifierCounts.total - b.bidModifierCounts.total,
    renderHeaderCell: () => 'Корректировки',
    renderCell: (item) => <SyncStat counter={item.bidModifierCounts} />,
  }),
  createTableColumn({
    columnId: 'clicks',
    compare: (a, b) => a.forecast.clicks - b.forecast.clicks,
    renderHeaderCell: () => 'Клики',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.clicks} fact={item.fact.clicks} formatOptions={integerOptions} />
    ),
  }),
  createTableColumn({
    columnId: 'conversions',
    compare: (a, b) => a.forecast.conversions - b.forecast.conversions,
    renderHeaderCell: () => 'Конверсии',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.conversions} fact={item.fact.conversions} formatOptions={integerOptions} />
    ),
  }),
  createTableColumn({
    columnId: 'cpa',
    compare: (a, b) => a.forecast.cpa - b.forecast.cpa,
    renderHeaderCell: () => 'Цена конверсии',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.cpa} fact={item.fact.cpa} formatOptions={currencyOptions} />
    ),
  }),
  createTableColumn({
    columnId: 'cost',
    compare: (a, b) => a.forecast.cost - b.forecast.cost,
    renderHeaderCell: () => 'Расход',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.cost} fact={item.fact.cost} formatOptions={currencyOptions} />
    ),
  }),
];

const isMetricColumn = (columnId: TableColumnId): boolean =>
  columnId === 'clicks' || columnId === 'conversions' || columnId === 'cpa' || columnId === 'cost';

const useStyles = makeStyles({
  cellId: {
    width: '100px',
    maxWidth: '100px',
  },
  cellMetric: {
    width: '130px',
    maxWidth: '130px',
  },
  cellBids: {
    width: '100px',
    maxWidth: '100px',
  },
  cellBidModifiers: {
    width: '120px',
    maxWidth: '120px',
  },
});

export const CampaignsPanel = ({ className, projectId, task }: CampaignsPanelProps) => {
  const { data: campaignsResponse } = useGetCampaignsQuery({ project: projectId });
  const items = useMemo<CampaignItem[]>(
    () => buildCampaignListItems(task.campaigns, task.groups, campaignsResponse?.data || []),
    [task, campaignsResponse?.data]
  );

  const classes = useStyles();

  return (
    <div className={className} role="tabpanel" aria-labelledby="Рекламные кампании">
      <DataGrid columns={columns} items={items} sortable>
        <DataGridHeader>
          <DataGridRow>
            {({ columnId, renderHeaderCell }) => (
              <DataGridHeaderCell
                className={mergeClasses(
                  columnId === 'id' && classes.cellId,
                  columnId === 'bids' && classes.cellBids,
                  columnId === 'bidModifiers' && classes.cellBidModifiers,
                  isMetricColumn(columnId) && classes.cellMetric
                )}
              >
                {renderHeaderCell()}
              </DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<CampaignItem>>
          {({ item, rowId }) => (
            <DataGridRow<CampaignItem> key={rowId}>
              {({ columnId, renderCell }) => (
                <DataGridCell
                  className={mergeClasses(
                    columnId === 'id' && classes.cellId,
                    columnId === 'bids' && classes.cellBids,
                    columnId === 'bidModifiers' && classes.cellBidModifiers,
                    isMetricColumn(columnId) && classes.cellMetric
                  )}
                >
                  {renderCell(item)}
                </DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  );
};

export default CampaignsPanel;
