import { Field, FieldProps, Input, InputProps } from '@fluentui/react-components';
import { useCallback } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { Transform, TransformProps } from './types';
import { enhanceFieldProps, getInputValue, getTransformOutputFunction, mergeCallbacks } from './utils';

export type InputFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  FieldProps,
  'children' | 'validationMessage' | 'validationState'
> &
  Omit<InputProps, 'value'> &
  UseControllerProps<TFieldValues> &
  TransformProps;

type InputChangeEventHandler = NonNullable<InputProps['onChange']>;

const defaultNumberTransformer: Transform = {
  input: (value: number | undefined): string | undefined => (value === undefined ? value : `${value}`),
  output: (value: string): number => {
    if (value === '') {
      return 0;
    }

    return Number(value);
  },
};

export const InputField = <TFieldValues extends FieldValues = FieldValues>({
  className,
  onChange,
  onBlur,
  transform,
  label,
  hint,
  ...rest
}: InputFieldProps<TFieldValues>) => {
  const { field, fieldState } = useController(rest);

  if (rest.type === 'number' && !transform) {
    transform = defaultNumberTransformer;
  }

  const transformOutput = getTransformOutputFunction(transform);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBlur = useCallback(mergeCallbacks(onBlur, field.onBlur), [onBlur, field.onBlur]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback<InputChangeEventHandler>(
    mergeCallbacks(onChange, (ev, data) => {
      field.onChange(transformOutput(data.value));
    }),
    [onChange, field.onChange, transformOutput]
  );

  return (
    <Field className={className} label={label} hint={hint} {...enhanceFieldProps(rest, fieldState)}>
      <Input
        ref={field.ref}
        onBlur={handleBlur}
        onChange={handleChange}
        value={getInputValue(field, transform)}
        {...rest}
      />
    </Field>
  );
};
