import { makeStyles, tokens } from '@fluentui/react-components';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { GetForecastsApiResponse, useGetForecastsQuery } from '../../app/services/optimizer';
import EmptyState from '../../components/EmptyState';
import FetchError from '../../components/FetchError';
import { Loader } from '../../components/Loader';
import Pagination from '../../components/Pagination';
import TaskList from '../../features/task/TaskList';
import { usePageNumber } from '../../hooks/usePageNumber';
import { ProjectParamKeys } from '../../router';

const PAGE_SIZE = 50;

const useStyles = makeStyles({
  list: {
    marginTop: tokens.spacingVerticalL,
    marginBottom: tokens.spacingVerticalL,
  },
  pagination: {
    marginBottom: tokens.spacingVerticalL,
  },
});

function getTotal(response?: GetForecastsApiResponse): number {
  if (!response) {
    return 0;
  }

  return response.meta.total;
}

export const Tasks = () => {
  const { project } = useParams<ProjectParamKeys>();
  const [pageNumber, setPageNumber] = usePageNumber();
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useGetForecastsQuery(
    project
      ? {
          project,
          limit: PAGE_SIZE,
          offset: pageNumber * PAGE_SIZE,
        }
      : skipToken
  );
  const classes = useStyles();

  if (isLoading) {
    return <Loader message="Загрузка списка задач..." />;
  }

  if (error) {
    return <FetchError error={error} onRetry={refetch} />;
  }

  const totalItemsCount = getTotal(response);

  if (!response || totalItemsCount === 0) {
    return (
      <EmptyState
        className={classes.list}
        primaryMessage="Пока нет ни одной задачи"
        secondaryMessage="Задачи будут автоматически создаваться ежедневно, пока проект активен."
      />
    );
  }

  return (
    <>
      <TaskList className={classes.list} items={response.data} />
      <Pagination
        className={classes.pagination}
        selectedPageIndex={pageNumber}
        itemsPerPage={PAGE_SIZE}
        totalItemsCount={totalItemsCount}
        onPageChange={setPageNumber}
      />
    </>
  );
};

export default Tasks;
