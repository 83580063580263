import { useMemo, useState } from 'react';
import { GroupItem } from '../../../app/services/optimizer';

export type GroupFilterValue = GroupItem['id'] | 'WITHOUT_GROUP' | undefined;

type FilterState = {
  query: string;
  group: GroupFilterValue;
};

type FilterMethods = {
  setQuery: (query: string) => void;
  setGroup: (group: GroupFilterValue) => void;
  reset: () => void;
};

export type FilterableItem = {
  getContent: () => string;
  getGroupId: () => GroupItem['id'] | undefined;
};

const defaultState: FilterState = {
  query: '',
  group: undefined,
};

export function useFilter(): [FilterState, FilterMethods] {
  const [state, setState] = useState<FilterState>(defaultState);
  const methods = useMemo<FilterMethods>(
    () => ({
      setQuery: (query) => {
        setState((prevState) => ({
          ...prevState,
          query,
        }));
      },
      setGroup: (group) => {
        setState((prevState) => ({
          ...prevState,
          group,
        }));
      },
      reset: () => {
        setState(defaultState);
      },
    }),
    [setState]
  );

  return [state, methods];
}

export function applyFilter<TItem extends FilterableItem>(items: TItem[], filter: FilterState): TItem[] {
  const { query, group } = filter;
  const predicates: ((item: TItem) => boolean)[] = [];

  if (query.length >= 2) {
    const terms = query.split(' ').map((term) => term.trim().toLowerCase());
    predicates.push((item) => {
      const content = item.getContent().toLowerCase();
      return terms.every((term) => content.includes(term));
    });
  }

  if (group === 'WITHOUT_GROUP') {
    predicates.push((item) => item.getGroupId() === undefined);
  } else if (group) {
    predicates.push((item) => item.getGroupId() === group);
  }

  if (predicates.length === 0) {
    return items;
  }

  return items.filter((item) => predicates.every((predicate) => predicate(item)));
}
