import {
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  mergeClasses,
  TableCellActions,
  TableCellLayout,
  TableColumnDefinition,
} from '@fluentui/react-components';
import {
  AddSquareFilled,
  AddSquareRegular,
  bundleIcon,
  DeleteFilled,
  DeleteRegular,
  EditFilled,
  EditRegular,
} from '@fluentui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedNumber } from 'react-intl';
import EmptyState from '../../../components/EmptyState';
import { currencyOptions } from '../../../utils/intl';
import { FormValues, GroupValues } from '../form';
import ConstraintsInfo from './ConstraintsInfo';
import GroupFormDialog from './GroupFormDialog';

const AddIcon = bundleIcon(AddSquareFilled, AddSquareRegular);
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

type GroupState = {
  group: GroupValues;
  campaignsCount: number;
  edit: () => void;
  remove: () => void;
};

type State = {
  openFormDialog: boolean;
  editingGroup?: GroupValues;
};

const defaultState: State = {
  openFormDialog: false,
  editingGroup: undefined,
};

const columns: TableColumnDefinition<GroupState>[] = [
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => a.group.name.localeCompare(b.group.name),
    renderHeaderCell: () => 'Название',
    renderCell: (item) => (
      <>
        <TableCellLayout>{item.group.name}</TableCellLayout>
        <TableCellActions>
          <Button icon={<EditIcon />} appearance="subtle" aria-label="Редактировать" onClick={item.edit} />
          <Button icon={<DeleteIcon />} appearance="subtle" aria-label="Удалить" onClick={item.remove} />
        </TableCellActions>
      </>
    ),
  }),
  createTableColumn({
    columnId: 'campaignsCount',
    compare: (a, b) => a.campaignsCount - b.campaignsCount,
    renderHeaderCell: () => 'Кол-во кампаний',
    renderCell: (item) => item.campaignsCount,
  }),
  createTableColumn({
    columnId: 'avgOrderValue',
    compare: (a, b) => a.group.avgOrderValue - b.group.avgOrderValue,
    renderHeaderCell: () => 'Средний чек',
    renderCell: (item) => {
      if (item.group.avgOrderValue) {
        return <FormattedNumber value={item.group.avgOrderValue} {...currencyOptions} />;
      }

      return '—';
    },
  }),
  createTableColumn({
    columnId: 'constraints',
    renderHeaderCell: () => 'Ограничения',
    renderCell: (item) => {
      return <ConstraintsInfo constraints={item.group.constraints} />;
    },
  }),
];

const useStyles = makeStyles({
  cellCampaignsCount: {
    width: '130px',
    maxWidth: '130px',
  },
  cellAvgOrderValue: {
    width: '100px',
    maxWidth: '100px',
  },
});

export const ManageGroups = () => {
  const [state, setState] = useState<State>(defaultState);
  const { control, watch } = useFormContext<FormValues>();
  const { append, remove, update } = useFieldArray<FormValues, 'groups'>({
    control,
    name: 'groups',
  });

  const [watchingCampaigns, watchingGroups] = watch(['campaigns', 'groups']);

  const groups = useMemo<GroupState[]>(() => {
    const map = new Map<GroupValues['id'], GroupState>(
      watchingGroups.map((group, index) => [
        group.id,
        {
          group,
          campaignsCount: 0,
          edit: () => {
            setState({
              openFormDialog: true,
              editingGroup: group,
            });
          },
          remove: () => {
            remove(index);
          },
        },
      ])
    );

    for (const campaign of watchingCampaigns) {
      if (!campaign.groupId || !map.has(campaign.groupId)) {
        continue;
      }

      (map.get(campaign.groupId) as GroupState).campaignsCount += 1;
    }

    return Array.from(map.values());
  }, [watchingGroups, remove, watchingCampaigns, setState]);

  const handleAddClick = useCallback(() => {
    setState({
      openFormDialog: true,
    });
  }, [setState]);
  const handleDialogClose = useCallback(() => {
    setState(defaultState);
  }, [setState]);
  const handleChange = useCallback(
    (group: GroupValues) => {
      const index = watchingGroups.findIndex((item) => item.id === group.id);

      if (index === -1) {
        append(group);
      } else {
        update(index, group);
      }

      setState(defaultState);
    },
    [append, update, watchingGroups, setState]
  );

  const classes = useStyles();

  return (
    <>
      <Button icon={<AddIcon />} appearance="transparent" onClick={handleAddClick}>
        Добавить
      </Button>
      {groups.length === 0 && <EmptyState primaryMessage="Нет ни одной группы" />}
      {groups.length > 0 && (
        <DataGrid columns={columns} items={groups} sortable>
          <DataGridHeader>
            <DataGridRow>
              {({ columnId, renderHeaderCell }) => (
                <DataGridHeaderCell
                  className={mergeClasses(
                    columnId === 'campaignsCount' && classes.cellCampaignsCount,
                    columnId === 'avgOrderValue' && classes.cellAvgOrderValue
                  )}
                >
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<GroupState>>
            {({ item, rowId }) => (
              <DataGridRow<GroupState> key={rowId}>
                {({ columnId, renderCell }) => (
                  <DataGridCell
                    className={mergeClasses(
                      columnId === 'campaignsCount' && classes.cellCampaignsCount,
                      columnId === 'avgOrderValue' && classes.cellAvgOrderValue
                    )}
                  >
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      )}
      {state.openFormDialog && (
        <GroupFormDialog group={state.editingGroup} onChange={handleChange} onClose={handleDialogClose} />
      )}
    </>
  );
};

export default ManageGroups;
