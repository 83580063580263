import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  TableColumnDefinition,
  tokens,
} from '@fluentui/react-components';
import { currencyOptions, integerOptions } from '../../../utils/intl';
import { GroupStat } from '../data';
import MetricValue from '../MetricValue';
import NoGroup from '../NoGroup';

export type StatGroupListProps = {
  items: GroupStat[];
};

const columns: TableColumnDefinition<GroupStat>[] = [
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => (a.name || '').localeCompare(b.name || ''),
    renderHeaderCell: () => 'Название',
    renderCell: (item) => {
      if (item.name) {
        return item.name;
      }

      return <NoGroup />;
    },
  }),
  createTableColumn({
    columnId: 'clicks',
    compare: (a, b) => a.forecast.clicks - b.forecast.clicks,
    renderHeaderCell: () => 'Клики',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.clicks} fact={item.fact.clicks} formatOptions={integerOptions} />
    ),
  }),
  createTableColumn({
    columnId: 'conversions',
    compare: (a, b) => a.forecast.conversions - b.forecast.conversions,
    renderHeaderCell: () => 'Конверсии',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.conversions} fact={item.fact.conversions} formatOptions={integerOptions} />
    ),
  }),
  createTableColumn({
    columnId: 'cpa',
    compare: (a, b) => a.forecast.cpa - b.forecast.cpa,
    renderHeaderCell: () => 'Цена конверсии',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.cpa} fact={item.fact.cpa} formatOptions={currencyOptions} />
    ),
  }),
  createTableColumn({
    columnId: 'cost',
    compare: (a, b) => a.forecast.cost - b.forecast.cost,
    renderHeaderCell: () => 'Расход',
    renderCell: (item) => (
      <MetricValue forecast={item.forecast.cost} fact={item.fact.cost} formatOptions={currencyOptions} />
    ),
  }),
];

const useStyles = makeStyles({
  gridRow: {
    paddingTop: tokens.spacingVerticalXS,
    paddingBottom: tokens.spacingVerticalXS,
  },
});

export const StatGroupList = ({ items }: StatGroupListProps) => {
  const classes = useStyles();

  return (
    <DataGrid columns={columns} items={items} sortable>
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<GroupStat>>
        {({ item, rowId }) => (
          <DataGridRow<GroupStat> key={rowId} className={classes.gridRow}>
            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};

export default StatGroupList;
