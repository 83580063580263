import { makeStyles, mergeClasses, shorthands, tokens, typographyStyles } from '@fluentui/react-components';
import React from 'react';

export type StatProps = {
  label: string;
  number: string;
  description?: React.ReactNode;
  className?: string;
};

const useStyles = makeStyles({
  root: {
    ...shorthands.margin(0),
    '& dd, & dt': {
      ...shorthands.margin(0),
    },
    '& .stat__label': {
      ...typographyStyles.subtitle2,
      color: tokens.colorNeutralStrokeAccessible,
    },
    '& .stat__number': typographyStyles.title2,
    '& .stat__description': {
      color: tokens.colorNeutralForegroundDisabled,
    },
  },
});

export const Stat = ({ label, number, description, className }: StatProps) => {
  const classes = useStyles();

  return (
    <dl className={mergeClasses('stat', classes.root, className)}>
      <dt className="stat__label">{label}</dt>
      <dd className="stat__number">{number}</dd>
      {description ? <dd className="stat__description">{description}</dd> : null}
    </dl>
  );
};

export default Stat;
