import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const ERR_CODE_PERMISSION_DENIED = 403;
export const ERR_CODE_NOT_FOUND = 404;

export type ApiError = {
  status: number;
  data: {
    message: string;
    reason?: string;
  };
};

export function isFetchError(err: any): err is FetchBaseQueryError {
  return err?.status && (typeof err.status === 'number' || typeof err.status === 'string');
}

export function isApiError(err: unknown): err is ApiError {
  return (
    isFetchError(err) &&
    err.status >= 400 &&
    err.status <= 599 &&
    typeof err.data === 'object' &&
    err.data !== null &&
    'message' in err.data
  );
}

export function getApiErrorCode(err: any): number {
  if (isApiError(err) && typeof err.status === 'number') {
    return err.status;
  }

  return 0;
}

export function getApiErrorReason<T extends string>(err: any): T | undefined {
  if (isApiError(err)) {
    return err.data.reason as T;
  }

  return undefined;
}
