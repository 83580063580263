import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { USER_API_BASE_URL } from '../../config';
import { storeSession } from '../session';
import { retry } from '../utils/retry';

export interface TokenCredentials {
  accessToken: string;
  refreshToken: string;
}

export interface SignInRequest {
  email: string;
  password: string;
}

interface SignInResponse {
  data: {
    access_token: string;
    refresh_token: string;
  };
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: USER_API_BASE_URL,
    })
  ),
  endpoints: (builder) => ({
    signIn: builder.mutation<TokenCredentials, SignInRequest>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: { data: credentials },
      }),
      transformResponse: (response: SignInResponse): TokenCredentials => ({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const {
            data: { accessToken, refreshToken },
          } = await queryFulfilled;
          storeSession(accessToken, refreshToken);
        } catch (err) {
          // no handle
        }
      },
    }),
  }),
});

export const { useSignInMutation } = authApi;
