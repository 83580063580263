import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Slider,
  SliderProps,
} from '@fluentui/react-components';
import { useCallback, useState } from 'react';
import { GoalItem } from '../../../app/services/optimizer';
import { useDialogStyles } from './dialogStyles';
import { SelectedGoal } from './SelectedGoal';

export type EditGoalDialogProps = {
  goal: SelectedGoal;
  onChange: (goal: GoalItem) => void;
  onDismiss: () => void;
};

type SliderChangeEventHandler = NonNullable<SliderProps['onChange']>;

export const EditGoalDialog = ({ goal, onChange, onDismiss }: EditGoalDialogProps) => {
  const [weight, setWeight] = useState<number>(goal.weight);
  const handleWeightChange = useCallback<SliderChangeEventHandler>(
    (ev, data) => {
      setWeight(data.value);
    },
    [setWeight]
  );
  const handleApplyClick = useCallback(() => {
    onChange({
      id: goal.id,
      weight,
    });
  }, [goal, weight, onChange]);
  const dialogClasses = useDialogStyles();

  return (
    <Dialog open={true} onOpenChange={onDismiss}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={dialogClasses.content}>{goal.name}</DialogTitle>
          <DialogContent className={dialogClasses.content}>
            <Field label="Вес" hint={`Текущее значение: ${weight}%`}>
              <Slider min={1} max={100} value={weight} onChange={handleWeightChange} />
            </Field>
          </DialogContent>
          <DialogActions className={dialogClasses.content}>
            <Button appearance="primary" onClick={handleApplyClick}>
              Применить
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Закрыть</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default EditGoalDialog;
