import { makeStyles, tokens } from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    color: tokens.colorNeutralForegroundDisabled,
  },
});

export const NoGroup = () => {
  const classes = useStyles();

  return <span className={classes.root}>(нет группы)</span>;
};

export default NoGroup;
