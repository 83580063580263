import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogProps,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  tokens,
} from '@fluentui/react-components';
import { vestResolver } from '@hookform/resolvers/vest';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { InputField } from '../../../components/form';
import { ConstraintsField } from '../ConstraintsField';
import { GroupValues } from '../form';
import { generateId } from './utils/generateId';
import { validationSuite } from './validation';

export type GroupFormDialogProps = {
  group?: GroupValues;
  onChange: (data: GroupValues) => void;
  onClose: () => void;
};

type OpenChangeEventHandler = NonNullable<DialogProps['onOpenChange']>;
type FormValues = Omit<GroupValues, 'id'>;

const defaultValues: FormValues = {
  name: '',
  avgOrderValue: 0,
  constraints: {
    current: [],
    next: [],
  },
};

const useStyles = makeStyles({
  field: {
    marginBottom: tokens.spacingVerticalM,
  },
  dialogContent: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
});

export const GroupFormDialog = ({ group, onChange, onClose }: GroupFormDialogProps) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: group || defaultValues,
    resolver: vestResolver(validationSuite),
  });
  const onSubmit = useCallback(
    (data: FormValues) => {
      onChange({
        ...data,
        id: group?.id || generateId(),
      });
    },
    [onChange, group]
  );
  const handleOpenChange = useCallback<OpenChangeEventHandler>(
    (ev, data) => {
      if (!data.open) {
        onClose();
      }
    },
    [onClose]
  );

  const classes = useStyles();

  return (
    <Dialog open modalType="modal" onOpenChange={handleOpenChange}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={classes.dialogContent}>
            {group ? 'Редактирование' : 'Добавление новой группы'}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <InputField className={classes.field} control={control} name="name" label="Название" />
            <InputField
              className={classes.field}
              control={control}
              name="avgOrderValue"
              label="Средний чек"
              type="number"
              min={0}
            />
            <ConstraintsField className={classes.field} control={control} name="constraints" label="Ограничения" />
          </DialogContent>
          <DialogActions className={classes.dialogContent}>
            <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
              Применить
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Отмена</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default GroupFormDialog;
