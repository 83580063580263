import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@fluentui/react-components';
import { useMemo } from 'react';
import { Goal, GoalItem, useGetGoalsQuery } from '../../../app/services/optimizer';

export type GoalsListProps = {
  className?: string;
  projectId: string;
  goals: GoalItem[];
};

type ListItem = {
  id: number;
  name: string;
  weight: number;
};

const useStyles = makeStyles({
  cellId: {
    width: '100px',
  },
  cellWeight: {
    width: '100px',
  },
});

export const GoalsList = ({ className, projectId, goals }: GoalsListProps) => {
  const { data: goalsResponse } = useGetGoalsQuery({ project: projectId });
  const items = useMemo<ListItem[]>(() => {
    const goalsMap = new Map<number, Goal>((goalsResponse?.data || []).map((goal) => [goal.id, goal]));

    return goals.map((item) => ({
      ...item,
      name: goalsMap.get(item.id)?.name || '',
    }));
  }, [goals, goalsResponse?.data]);

  const classes = useStyles();

  return (
    <Table className={className}>
      <TableHeader>
        <TableRow>
          <TableHeaderCell className={classes.cellId}>ID</TableHeaderCell>
          <TableHeaderCell>Название</TableHeaderCell>
          <TableHeaderCell className={classes.cellWeight}>Вес</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.weight}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GoalsList;
