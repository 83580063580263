import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  makeStyles,
  Slider,
  SliderProps,
  tokens,
} from '@fluentui/react-components';
import { useCallback, useState } from 'react';
import { Goal, GoalItem } from '../../../app/services/optimizer';
import { useDialogStyles } from './dialogStyles';
import { GoalsDropdown, GoalSelectEventHandler } from './GoalsDropdown';

export type AddGoalEventHandler = (item: GoalItem) => void;

export type AddGoalDialogProps = {
  goals: Goal[];
  onAdd: AddGoalEventHandler;
  onDismiss: () => void;
};

type SliderChangeEventHandler = NonNullable<SliderProps['onChange']>;

type State = {
  selectedGoal: Goal | undefined;
  weight: number;
};

const defaultState: State = {
  selectedGoal: undefined,
  weight: 100,
};

const useStyles = makeStyles({
  field: {
    marginBottom: tokens.spacingVerticalM,
  },
});

export const AddGoalDialog = ({ goals, onAdd, onDismiss }: AddGoalDialogProps) => {
  const [state, setState] = useState<State>(defaultState);
  const handleGoalSelect = useCallback<GoalSelectEventHandler>(
    (goal) => {
      setState((prevState) => ({
        ...prevState,
        selectedGoal: goal,
      }));
    },
    [setState]
  );
  const handleWeightChange = useCallback<SliderChangeEventHandler>(
    (ev, data) => {
      setState((prevState) => ({
        ...prevState,
        weight: data.value,
      }));
    },
    [setState]
  );
  const handleAddClick = useCallback(() => {
    if (state.selectedGoal) {
      onAdd({
        id: state.selectedGoal.id,
        weight: state.weight,
      });
    }
  }, [state, onAdd]);
  const dialogClasses = useDialogStyles();
  const classes = useStyles();

  return (
    <Dialog open={true} onOpenChange={onDismiss}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={dialogClasses.content}>Добавление новой цели</DialogTitle>
          <DialogContent className={dialogClasses.content}>
            <Field className={classes.field} label="Цель">
              <GoalsDropdown goals={goals} onSelect={handleGoalSelect} />
            </Field>
            <Field className={classes.field} label="Вес" hint={`Текущее значение: ${state.weight}%`}>
              <Slider min={1} max={100} value={state.weight} onChange={handleWeightChange} />
            </Field>
          </DialogContent>
          <DialogActions className={dialogClasses.content}>
            <Button appearance="primary" onClick={handleAddClick} disabled={!state.selectedGoal}>
              Добавить
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Закрыть</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AddGoalDialog;
