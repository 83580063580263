import { makeStaticStyles } from '@fluentui/react-components';
import { ReactElement } from 'react';
import Login from './features/auth/Login';
import { useAuth } from './hooks/useAuth';

const useStaticStyles = makeStaticStyles({
  html: {
    margin: 0,
    padding: 0,
  },
  body: {
    margin: 0,
    padding: 0,
  },
  '#root': {
    height: '100vh',
  },
  'input[type=number]::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  'input[type=number]::-webkit-outer-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    appearance: 'textfield',
  },
});

export type AppProps = {
  children: ReactElement;
};

export const App = ({ children }: AppProps) => {
  useStaticStyles();

  const authenticated = useAuth();

  if (!authenticated) {
    return <Login />;
  }

  return children;
};

export default App;
