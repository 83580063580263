import { useMemo, useState } from 'react';
import { Campaign } from '../../../../app/services/optimizer';

type FilterState = {
  query: string;
  showAll: boolean;
};

type FilterMethods = {
  setQuery: (query: string) => void;
  setShowAll: (showAll: boolean) => void;
  reset: () => void;
};

const defaultState: FilterState = {
  query: '',
  showAll: false,
};

export function useFilter(): [FilterState, FilterMethods] {
  const [state, setState] = useState<FilterState>(defaultState);
  const methods = useMemo<FilterMethods>(
    () => ({
      setQuery: (query) => {
        setState((prevState) => ({
          ...prevState,
          query,
        }));
      },
      setShowAll: (showAll) => {
        setState((prevState) => ({
          ...prevState,
          showAll,
        }));
      },
      reset: () => {
        setState(defaultState);
      },
    }),
    [setState]
  );

  return [state, methods];
}

type FilterPredicate = (item: Campaign) => boolean;

export function applyFilter(campaigns: Campaign[], filter: FilterState): Campaign[] {
  const { query, showAll } = filter;
  const predicates: FilterPredicate[] = [];

  if (query.length >= 2) {
    const terms = query.split(' ').map((term) => term.trim().toLowerCase());
    predicates.push((item) => {
      const content = (item.id + ' ' + item.name).toLowerCase();
      return terms.every((term) => content.includes(term));
    });
  }

  if (!showAll) {
    predicates.push((item) => item.selectable);
  }

  if (predicates.length === 0) {
    return campaigns;
  }

  return campaigns.filter((campaign) => predicates.every((predicate) => predicate(campaign)));
}
