import {
  Avatar,
  makeStyles,
  Menu,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from '@fluentui/react-components';
import { useCallback } from 'react';
import { useGetProfileQuery } from '../../app/services/user';
import { clearSession } from '../../app/session';
import { useAppDispatch } from '../../hooks/store';
import { loggedOut } from './authSlice';

export type ProfileMenuProps = {
  className?: string;
};

const useStyles = makeStyles({
  avatar: {
    cursor: 'pointer',
  },
});

export const ProfileMenu = ({ className }: ProfileMenuProps) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { data: profile } = useGetProfileQuery();
  const handleLogoutClick = useCallback(() => {
    clearSession();
    dispatch(loggedOut());
  }, [dispatch]);

  const { email } = profile || { email: undefined };

  return (
    <Menu>
      <MenuTrigger>
        <Avatar className={mergeClasses(classes.avatar, className)} color="colorful" name={email} />
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuGroup>
            <MenuGroupHeader>{email}</MenuGroupHeader>
            <MenuItem onClick={handleLogoutClick}>Выход</MenuItem>
          </MenuGroup>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default ProfileMenu;
