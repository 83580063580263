import { makeStyles, Spinner } from '@fluentui/react-components';

export type LoaderProps = {
  message?: string;
};

const useStyles = makeStyles({
  spinner: {
    height: '100%',
    width: '100%',
  },
});

export const Loader = ({ message }: LoaderProps) => {
  const classes = useStyles();

  return <Spinner className={classes.spinner} label={message} size="extra-large" />;
};
