import { UseFormSetError } from 'react-hook-form';
import { UpdateProjectSettingsReason } from '../../app/services/optimizer';
import { ApiError } from '../../app/utils/error';
import { FormValues } from './form';

const reasonToErrorMessageMap: Record<UpdateProjectSettingsReason, string> = {
  avg_order_value_required: 'Поле «Средний чек» обязательно для заполнения.',
  campaign_not_found: 'Одна или несколько из выбранных кампаний не найдены в Яндекс.Директе.',
  constraint_conflicts: 'На уровне проекта или группы заданы ограничения, которые конфликтуют между собой',
  constraint_duplication_detected: 'Есть дубликаты в ограничениях.',
  delete_campaigns_prohibited: 'Невозможно удалить ранее выбранные кампании, когда включен автоматический импорт.',
  goal_duplication_detected: 'Есть дубликаты в выбранных целях.',
  goal_not_found: 'Одна или несколько из выбранных целей не найдены в Яндекс.Директе.',
  version_mismatch: 'Невозможно сохранить изменения, так как на сервере более актуальная версия настроек.',
  yandex_api_unavailable:
    'Сервер Яндекс.Директ API временно недоступен. Пожалуйста, попробуйте повторить запрос позже.',
};

export function handleApiError(error: ApiError, setError: UseFormSetError<FormValues>): boolean {
  const errCode = error.status;
  const { reason } = error.data;
  const setRootError = (message: string) => {
    setError('root', {
      type: 'serverError',
      message,
    });
  };

  if (errCode === 403) {
    setRootError('Недостаточно прав для выполнения операции');

    return true;
  }

  if (errCode === 404) {
    setRootError('Проект не найден или был удален');

    return true;
  }

  if (reason && reason in reasonToErrorMessageMap) {
    setRootError(reasonToErrorMessageMap[reason as UpdateProjectSettingsReason]);

    return true;
  }

  if (errCode >= 500) {
    setRootError('Сервис временно недоступен. Пожалуйста, попробуйте повторить запрос позже.');

    return true;
  }

  return false;
}
