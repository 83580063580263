import { createBrowserRouter, Params, RouteObject } from 'react-router-dom';
import { getForecast } from './app/services/optimizer';
import { getProject } from './app/services/project';
import { AppDispatch } from './app/store';
import ErrorBoundary from './components/ErrorBoundary';
import Index from './routes/Index';
import OldRoutes from './routes/OldRoutes';
import Project from './routes/Project';
import Settings from './routes/project/Settings';
import Task from './routes/project/Task';
import Tasks from './routes/project/Tasks';
import Root from './routes/Root';

export type RouteMeta = {
  title: string | ((params: Params, dispatch: AppDispatch) => Promise<string>);
};

export type AppRouteObject = RouteObject & {
  children?: AppRouteObject[];
  handle?: RouteMeta;
};

export type ProjectParamKeys = 'project';
export type TaskParamKeys = ProjectParamKeys | 'task';

async function getProjectTitle(params: Params, dispatch: AppDispatch): Promise<string> {
  return getProject(dispatch, params.project!).then((project) => project.name);
}

async function getTaskTitle(params: Params, dispatch: AppDispatch): Promise<string> {
  return getForecast(dispatch, params.project!, Number(params.task!)).then((task) => `Задача: ${task.id}`);
}

export const routes: AppRouteObject[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorBoundary />,
    handle: {
      title: 'AirMedia.CostOptimizer',
    },
    children: [
      {
        errorElement: <ErrorBoundary nested />,
        children: [
          {
            index: true,
            element: <Index />,
          },
          {
            path: 'p/:project',
            element: <Project />,
            errorElement: <ErrorBoundary nested />,
            handle: {
              title: getProjectTitle,
            },
            children: [
              {
                index: true,
                element: <Settings />,
              },
              {
                path: 'settings',
                element: <Settings />,
                handle: {
                  title: 'Настройки',
                },
              },
              {
                path: 'tasks',
                children: [
                  {
                    index: true,
                    element: <Tasks />,
                    handle: {
                      title: 'Список задач',
                    },
                  },
                  {
                    path: ':task',
                    element: <Task />,
                    handle: {
                      title: getTaskTitle,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '*',
            element: <OldRoutes />,
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

export default router;
