import type { PreloadedState } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { authApi } from './services/auth';
import { optimizerApi } from './services/optimizer';
import { projectApi } from './services/project';
import { userApi } from './services/user';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [optimizerApi.reducerPath]: optimizerApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  auth: authReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        authApi.middleware,
        optimizerApi.middleware,
        projectApi.middleware,
        userApi.middleware
      ),
    preloadedState,
  });

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<typeof rootReducer>;
