import { makeStyles, SelectTabEventHandler, shorthands, Tab, TabList, tokens } from '@fluentui/react-components';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Outlet, resolvePath, useLocation, useParams } from 'react-router-dom';
import SharedAccountConflictAlert from '../features/settings/SharedAccountConflictAlert';
import { ProjectParamKeys } from '../router';

type NavItem = {
  to: string;
  name: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  leftPane: {
    width: '270px',
    position: 'relative',
    paddingTop: '45px',
    backgroundColor: tokens.colorNeutralBackground1Hover,
    ...shorthands.borderRight('1px', 'solid', tokens.colorNeutralBackground1Selected),
    '& > .ms-ScrollablePane': {
      top: '45px',
    },
  },
  navLink: {
    columnGap: tokens.spacingHorizontalSNudge,
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    ...shorthands.borderRadius(0),
    '&::after': {
      top: 0,
      bottom: 0,
    },
    '&::before': {
      top: 0,
      bottom: 0,
    },
  },
  main: {
    flexGrow: 1,
  },
});

const navItems: NavItem[] = [
  { to: 'settings', name: 'Настройки' },
  { to: 'tasks', name: 'Задачи' },
];

export const Project = () => {
  const navigate = useNavigate();
  const { pathname: locationPathname } = useLocation();
  const { project } = useParams<ProjectParamKeys>();
  const selectedTab = useMemo<string>(() => {
    for (const item of navItems) {
      const toPathname = resolvePath(item.to).pathname;

      if (locationPathname.search(toPathname) > 0) {
        return item.to;
      }
    }

    return navItems[0].to;
  }, [locationPathname]);
  const handleSelectTab = useCallback<SelectTabEventHandler>(
    (ev, { value }) => {
      navigate(value as string);
    },
    [navigate]
  );
  const classes = useStyles();

  return (
    <>
      {project && <SharedAccountConflictAlert projectId={project} />}
      <div className={classes.root}>
        {project && (
          <TabList className={classes.leftPane} vertical selectedValue={selectedTab} onTabSelect={handleSelectTab}>
            {navItems.map((item, index) => (
              <Tab key={index} className={classes.navLink} value={item.to}>
                {item.name}
              </Tab>
            ))}
          </TabList>
        )}
        <div className={classes.main}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Project;
