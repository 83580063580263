import { makeStyles, tokens, typographyStyles } from '@fluentui/react-components';
import { useMemo } from 'react';
import { Forecast } from '../../../app/services/optimizer';
import { getGroupsStats, getSummary } from '../data';
import StatGroupList from './StatGroupList';
import Summary from './Summary';

export type SummaryPanelProps = {
  className?: string;
  task: Forecast;
};

const useStyles = makeStyles({
  summary: {
    marginTop: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
  },
  header: {
    ...typographyStyles.title3,
  },
});

export const StatsPanel = ({ className, task }: SummaryPanelProps) => {
  const summary = useMemo(() => getSummary(task), [task]);
  const groupsStats = useMemo(() => getGroupsStats(task), [task]);

  const classes = useStyles();

  return (
    <div className={className} role="tabpanel" aria-labelledby="Статистика">
      <Summary
        className={classes.summary}
        forecast={summary.forecast}
        fact={summary.fact}
        dummy={task.status !== 'SUCCESS'}
      />
      {groupsStats.length > 0 && (
        <>
          <h2 className={classes.header}>Группы</h2>
          <StatGroupList items={groupsStats} />
        </>
      )}
    </div>
  );
};

export default StatsPanel;
