import { Link, makeStyles, shorthands, tokens, typographyStyles } from '@fluentui/react-components';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { AppDispatch } from '../app/store';
import { Loader } from '../components/Loader';
import Logo from '../components/Logo';
import ProfileMenu from '../features/auth/ProfileMenu';
import ProjectPicker, { SelectProjectEventHandler } from '../features/project/ProjectPicker';
import { useAppDispatch } from '../hooks/store';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { ProjectParamKeys, RouteMeta } from '../router';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: tokens.colorBrandBackground,
    ...shorthands.gap('32px'),
    ...shorthands.padding('0px', '32px'),
  },
  logo: {
    ...shorthands.margin('8px', '0px'),
    '& .svg': {
      fill: tokens.colorBrandBackgroundInvertedSelected,
    },
  },
  title: {
    flexGrow: 1,
    ...typographyStyles.subtitle1,
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipse',
    whiteSpace: 'nowrap',
    color: tokens.colorBrandBackgroundInvertedSelected,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& h1': {
      ...typographyStyles.title2,
    },
  },
});

type Match = ReturnType<typeof useMatches>[0];
type MatchWithMeta = Match & {
  handle: RouteMeta;
};

function hasTitle(match: Match): match is MatchWithMeta {
  if (match.handle && match.handle.hasOwnProperty('title')) {
    return true;
  }

  return false;
}

async function getTitle({ params, handle }: MatchWithMeta, dispatch: AppDispatch): Promise<string> {
  if (typeof handle.title === 'string') {
    return handle.title;
  }

  return handle.title(params, dispatch).catch(() => '<error>');
}

export const Root = () => {
  const { project: locationProjectId } = useParams<ProjectParamKeys>();
  const matches = useMatches();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleSelectProject = useCallback<SelectProjectEventHandler>(
    (project) => {
      navigate(`/p/${project.id}`);
    },
    [navigate]
  );

  useDocumentTitle(
    Promise.all(
      matches
        .filter(hasTitle)
        .map((match) => getTitle(match, dispatch))
        .reverse()
    )
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <Link href="/" className={classes.logo}>
          <Logo size={32} className="svg" />
        </Link>
        <div className={classes.title}>
          <ProjectPicker
            selectedProjectId={locationProjectId}
            selectFirstByDefault={true}
            onSelect={handleSelectProject}
          />
        </div>
        <ProfileMenu />
      </div>
      <main className={classes.main}>
        <React.Suspense fallback={<Loader />}>
          <Outlet />
        </React.Suspense>
      </main>
    </div>
  );
};

export default Root;
