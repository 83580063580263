import { AttributionModel, Campaign, OptimizationStrategy } from './api.generated';
import { ConstraintLabel } from './index';

type CampaignState = Campaign['state'];

const attributionModelLabels: Record<AttributionModel, string> = {
  FC: 'Первый переход',
  LC: 'Последний переход',
  LSC: 'Последний значимый переход',
  LYDC: 'Последний переход из Яндекс.Директа',
  FCCD: 'Кросс-девайс: Первый переход',
  LSCCD: 'Кросс-девайс: Последний значимый переход',
  LYDCCD: 'Кросс-девайс: Последний переход из Директа',
};
const optimizationStrategyLabels: Record<OptimizationStrategy, string> = {
  MAXIMUM_CONVERSIONS: 'Максимальное количество конверсий',
  MAXIMUM_CONVERSION_VALUE: 'Максимальное количество конверсий (с учетом "Цены конверсии")',
};
const constraintLabels: Record<ConstraintLabel, string> = {
  Cost: 'Расход',
  CPA: 'Цена конверсии',
  CRR: 'Доля рекламных расходов',
};
const campaignStates: Record<CampaignState, string> = {
  ARCHIVED: 'В архиве',
  CONVERTED: 'В архиве',
  OFF: 'Неактивна',
  ON: 'Активна',
  ENDED: 'Завершена',
  SUSPENDED: 'Остановлена',
};

export const translateAttributionModel = (attributionModel: AttributionModel): string =>
  attributionModelLabels[attributionModel] || attributionModel;
export const translateCampaignState = (state: CampaignState): string => campaignStates[state] || state;
export const translateConstraintLabel = (label: ConstraintLabel): string => constraintLabels[label] || label;
export const translateOptimizationStrategy = (strategy: OptimizationStrategy): string =>
  optimizationStrategyLabels[strategy] || strategy;

export const getAttributionModels = (): AttributionModel[] => Object.keys(attributionModelLabels) as AttributionModel[];
export const getConstraintLabels = (): ConstraintLabel[] => Object.keys(constraintLabels) as ConstraintLabel[];
export const getOptimizationStrategies = (): OptimizationStrategy[] =>
  Object.keys(optimizationStrategyLabels) as OptimizationStrategy[];
