import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ERR_CODE_NOT_FOUND, ERR_CODE_PERMISSION_DENIED, isApiError } from '../app/utils/error';
import rollbar from '../rollbar';
import { NotFoundError } from '../utils/NotFoundError';
import { Error, NoAccess, NotFound } from './error';

export type ErrorBoundaryProps = {
  nested?: boolean;
};

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  rootNested: {
    height: '100%',
  },
});

export const ErrorBoundary = ({ nested = false }: ErrorBoundaryProps) => {
  const classes = useStyles();
  const error = useRouteError();

  const rootClasses = [classes.root, nested && classes.rootNested];

  if (isRouteErrorResponse(error) || isApiError(error)) {
    if (error.status === ERR_CODE_PERMISSION_DENIED) {
      return <NoAccess className={mergeClasses(...rootClasses)} />;
    }
    if (error.status === ERR_CODE_NOT_FOUND) {
      return <NotFound className={mergeClasses(...rootClasses)} />;
    }
  } else if (error instanceof NotFoundError) {
    return <NotFound className={mergeClasses(...rootClasses)} />;
  } else if (error) {
    rollbar.critical('Occurred an error while rendering components', error);
  }

  return (
    <Error className={mergeClasses(...rootClasses)}>
      <h1>Ох, нет!</h1>
      <p>Мы пытались нарисовать красивую страницу, но что-то пошло не так.</p>
      <p>Если ошибка повторится, пожалуйста, обратитесь в поддержку сервиса.</p>
    </Error>
  );
};

export default ErrorBoundary;
