import { DEBUG, SESSION_KEY } from '../config';

type Token = string | undefined;

let currentAccessToken: Token;
let currentRefreshToken: Token;

function initLocal(encodedSession: string) {
  try {
    [currentAccessToken, currentRefreshToken] = JSON.parse(encodedSession);
  } catch (err) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.warn('Could not parse session', err);
    }
  }
}

export function initSession(onHasData?: () => void) {
  const data = localStorage.getItem(SESSION_KEY);

  if (data) {
    initLocal(data);
  }

  window.addEventListener('storage', function (e: StorageEvent) {
    if (e.key === null) {
      clearSession();
    }

    if (e.key !== SESSION_KEY) {
      return;
    }

    if (e.newValue === null) {
      clearSession();
    } else {
      initLocal(e.newValue);
    }
  });

  if (onHasData && currentAccessToken && currentRefreshToken) {
    onHasData();
  }
}

export function getAccessToken(): Token {
  return currentAccessToken;
}

export function getRefreshToken(): Token {
  return currentRefreshToken;
}

export function storeSession(accessToken: string, refreshToken: string) {
  localStorage.setItem(SESSION_KEY, JSON.stringify([accessToken, refreshToken]));
  currentAccessToken = accessToken;
  currentRefreshToken = refreshToken;
}

export function clearSession() {
  if (localStorage.getItem(SESSION_KEY)) {
    localStorage.removeItem(SESSION_KEY);
  }

  currentAccessToken = undefined;
  currentRefreshToken = undefined;
}
