import { Checkbox, CheckboxProps, Field, FieldProps, makeStyles } from '@fluentui/react-components';
import { InfoButton, InfoButtonProps } from '@fluentui/react-components/unstable';
import { useCallback } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { TransformProps } from './types';
import { enhanceFieldProps, getInputValue, getTransformOutputFunction, mergeCallbacks } from './utils';

export type CheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  FieldProps,
  'children' | 'hint' | 'label' | 'validationMessage' | 'validationState'
> &
  Omit<CheckboxProps, 'checked'> &
  UseControllerProps<TFieldValues> &
  TransformProps & {
    hint?: FieldProps['hint'] | InfoButtonProps['info'];
    hintAppearance?: 'default' | 'info-button';
  };

type CheckboxChangeEventHandler = NonNullable<CheckboxProps['onChange']>;

const useStyles = makeStyles({
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const CheckboxField = <TFieldValues extends FieldValues = FieldValues>({
  className,
  label,
  hint,
  hintAppearance = 'default',
  transform,
  onChange,
  onBlur,
  ...rest
}: CheckboxFieldProps<TFieldValues>) => {
  const { field, fieldState } = useController(rest);
  const classes = useStyles();

  const transformOutput = getTransformOutputFunction(transform);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBlur = useCallback(mergeCallbacks(onBlur, field.onBlur), [onBlur, field.onBlur]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback<CheckboxChangeEventHandler>(
    mergeCallbacks(onChange, (ev, data) => {
      field.onChange(transformOutput(data.checked));
    }),
    [onChange, field.onChange, transformOutput]
  );

  if (hint && hintAppearance === 'info-button') {
    return (
      <Field className={className} {...enhanceFieldProps(rest, fieldState)}>
        <div className={classes.checkboxWrapper}>
          <Checkbox
            ref={field.ref}
            label={label}
            checked={getInputValue(field, transform)}
            onBlur={handleBlur}
            onChange={handleChange}
            {...rest}
          />
          {hintAppearance === 'info-button' && <InfoButton info={hint} />}
        </div>
      </Field>
    );
  }

  return (
    <Field hint={hint} {...enhanceFieldProps(rest, fieldState)}>
      <Checkbox
        ref={field.ref}
        label={label}
        checked={getInputValue(field, transform)}
        onBlur={handleBlur}
        onChange={handleChange}
        {...rest}
      />
    </Field>
  );
};
