import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';

export type LogoProps = {
  className?: string;
  size?: number;
};

const useStyles = makeStyles({
  root: {
    fill: tokens.colorBrandForegroundLinkHover,
  },
});

export const Logo = ({ className, size = 512 }: LogoProps) => {
  const classes = useStyles();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size}>
      <g xmlns="http://www.w3.org/2000/svg" className={mergeClasses(classes.root, className)}>
        <path d="M143.566 256.219c0 62.066 50.495 112.561 112.561 112.561 149.123-5.658 149.082-219.487-.001-225.123-62.065.001-112.56 50.496-112.56 112.562zm205.132 0c0 51.044-41.527 92.571-92.571 92.571-122.64-4.654-122.606-180.507.001-185.142 51.043 0 92.57 41.528 92.57 92.571z" />
        <path d="M216.316 251.073c-3.903-3.903-10.232-3.903-14.136 0-3.903 3.903-3.903 10.232 0 14.136l29.971 29.971c3.903 3.903 10.232 3.903 14.136 0l63.786-63.786c3.903-3.903 3.903-10.232 0-14.136-3.904-3.903-10.231-3.903-14.136 0l-56.719 56.72z" />
        <path d="M512 256.219c0-37.165-7.808-73.026-23.206-106.585-2.303-5.018-8.237-7.218-13.252-4.916-5.018 2.302-7.219 8.236-4.917 13.252 28.509 61.152 28.371 135.17.064 196.35l-8.371-22.574a9.999 9.999 0 00-11.048-6.378l-26.678 4.538c52.65-117.912-34.37-255.152-163.499-257.469l-21.585-26.042 21.556-26.007c61.257 1.249 118.646 25.729 162.051 69.237 3.898 3.91 10.228 3.916 14.135.017 3.908-3.899 3.916-10.227.017-14.136C389 27.127 324.788.437 256.457.351h-.007l-.323-.003C60.636-1.001-64.084 215.697 34.505 384.171c98.084 170.087 345.422 169.935 443.333-.152l.002-.004c22.348-38.689 34.16-82.88 34.16-127.796zm-92.015 0c0 28.778-7.567 57.083-21.884 81.857l-.002.003c-43.66 77.976-148.621 105.721-225.059 59.397C30.461 312 89.62 93.917 256.064 92.361l.263.002h.002c90.241.111 163.656 73.616 163.656 163.856zM89.334 89.425c39.38-39.38 90.236-63.123 144.995-68.104l-15.497 18.698a9.996 9.996 0 000 12.757l17.151 20.692C109.077 85.398 31.295 231.517 94.548 343.98l-11.786 31.783-33.243-5.654C-1.052 280.757 15.497 161.122 89.334 89.425zm9.289 301.057l9.394-25.335c76.277 104.875 237.87 97.929 304.96-12.986l33.477-5.694 11.704 31.562c-20.237 33.49-48.632 61.606-82.366 81.507-102.4 62.264-244.033 31.947-311.985-66.72l23.767 4.043c.576.145 3.918.926 7.157-1.131 2.83-1.795 3.697-4.557 3.892-5.246z" />
        <path d="M449.281 120.367c2.067 5.043 8.03 7.487 13.053 5.407 11.984-5.646 4.812-22.934-7.646-18.451-5.021 2.085-7.503 8.013-5.407 13.044z" />
      </g>
    </svg>
  );
};

export default Logo;
