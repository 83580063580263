import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useGetForecastQuery } from '../../app/services/optimizer';
import { NotFound } from '../../components/error';
import FetchError from '../../components/FetchError';
import { Loader } from '../../components/Loader';
import { TaskDetails } from '../../features/task/TaskDetails';
import { TaskParamKeys } from '../../router';

function parseTaskId(id: string): number | undefined {
  if (id?.match(/^\d+$/)) {
    return Number(id);
  }

  return undefined;
}

export const Task = () => {
  const { project, task } = useParams<TaskParamKeys>();
  const taskId = parseTaskId(task as string);
  const {
    data: forecast,
    error,
    isLoading,
    refetch,
  } = useGetForecastQuery(
    project && taskId
      ? {
          project: project,
          forecast: taskId,
        }
      : skipToken
  );

  if (!taskId || !project) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Loader message="Пожалуйста, подождите..." />;
  }

  if (error) {
    return <FetchError error={error} onRetry={refetch} />;
  }

  if (!forecast) {
    return <NotFound />;
  }

  return <TaskDetails projectId={project} task={forecast.data} />;
};

export default Task;
