import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectSettings: build.query<GetProjectSettingsApiResponse, GetProjectSettingsApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/settings` }),
    }),
    updateProjectSettings: build.mutation<UpdateProjectSettingsApiResponse, UpdateProjectSettingsApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/settings`, method: 'PUT', body: queryArg.body }),
    }),
    updateSharedAccount: build.mutation<UpdateSharedAccountApiResponse, UpdateSharedAccountApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.project}/shared-account`,
        method: 'PUT',
        body: queryArg.updateSharedAccountRequest,
      }),
    }),
    suspend: build.mutation<SuspendApiResponse, SuspendApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/suspended`, method: 'PUT' }),
    }),
    unsuspend: build.mutation<UnsuspendApiResponse, UnsuspendApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/suspended`, method: 'DELETE' }),
    }),
    getCampaigns: build.query<GetCampaignsApiResponse, GetCampaignsApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/campaigns` }),
    }),
    getGoals: build.query<GetGoalsApiResponse, GetGoalsApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/goals` }),
    }),
    getForecasts: build.query<GetForecastsApiResponse, GetForecastsApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.project}/forecasts`,
        params: { offset: queryArg.offset, limit: queryArg.limit },
      }),
    }),
    getForecast: build.query<GetForecastApiResponse, GetForecastApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.project}/forecasts/${queryArg.forecast}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetProjectSettingsApiResponse = /** status 200 Get settings */ {
  data: GetProjectSettings;
};
export type GetProjectSettingsApiArg = {
  /** Идентификатор проекта */
  project: string;
};
export type UpdateProjectSettingsApiResponse = /** status 200 Get settings */ {
  data: GetProjectSettings;
};
export type UpdateProjectSettingsApiArg = {
  /** Идентификатор проекта */
  project: string;
  body: {
    data: UpdateProjectSettings;
  };
};
export type UpdateSharedAccountApiResponse = /** status 204 Action applied */ undefined;
export type UpdateSharedAccountApiArg = {
  /** Идентификатор проекта */
  project: string;
  updateSharedAccountRequest: UpdateSharedAccountRequest;
};
export type SuspendApiResponse = /** status 204 Action applied */ undefined;
export type SuspendApiArg = {
  /** Идентификатор проекта */
  project: string;
};
export type UnsuspendApiResponse = /** status 204 Action applied */ undefined;
export type UnsuspendApiArg = {
  /** Идентификатор проекта */
  project: string;
};
export type GetCampaignsApiResponse = /** status 200 List of campaigns */ {
  meta?: ListMeta;
  data: Campaign[];
};
export type GetCampaignsApiArg = {
  /** Идентификатор проекта */
  project: string;
};
export type GetGoalsApiResponse = /** status 200 List of goals */ {
  meta?: ListMeta;
  data: Goal[];
};
export type GetGoalsApiArg = {
  /** Идентификатор проекта */
  project: string;
};
export type GetForecastsApiResponse = /** status 200 List of forecasts */ {
  meta: PageMeta;
  data: ForecastItem[];
};
export type GetForecastsApiArg = {
  /** Идентификатор проекта */
  project: string;
  /** Количество записей, которое должно быть пропущено при выборе */
  offset?: number;
  /** Максимальное количество записей в ответе */
  limit?: number;
};
export type GetForecastApiResponse = /** status 200 Forecast details */ {
  data: Forecast;
};
export type GetForecastApiArg = {
  /** Идентификатор проекта */
  project: string;
  /** Forecast identifier */
  forecast: number;
};
export type OptimizationStrategy = 'MAXIMUM_CONVERSIONS' | 'MAXIMUM_CONVERSION_VALUE';
export type AttributionModel = 'FC' | 'LC' | 'LSC' | 'LYDC' | 'FCCD' | 'LSCCD' | 'LYDCCD';
export type GetTimeTargetingSettings = {
  enabled: boolean;
  lastUpdateTime?: string | null;
};
export type GoalItem = {
  id: number;
  weight: number;
};
export type Constraint = {
  label: 'Cost' | 'CPA' | 'CRR';
  min: number;
  max: number;
};
export type DateBasedConstraints = {
  notBefore: string;
  constraints: Constraint[];
};
export type GroupItem = {
  id: string;
  name: string;
  avgOrderValue: number;
  constraints: DateBasedConstraints[];
};
export type CampaignItem = {
  id: number;
  groupId?: string | null;
  addedAt?: string;
};
export type GetProjectSettings = {
  version: number;
  projectId?: string;
  suspended: boolean;
  includeVAT: boolean;
  optimizationStrategy: OptimizationStrategy;
  attributionModel: AttributionModel;
  autoImportCampaigns: boolean;
  createBidModifiers: boolean;
  timeTargeting: GetTimeTargetingSettings;
  monthlyBudget: boolean;
  avgOrderValue: number;
  goals: GoalItem[];
  constraints: DateBasedConstraints[];
  groups?: GroupItem[];
  campaigns: CampaignItem[];
  sharedAccountConflict?: boolean;
};
export type ApiError = {
  message: string;
  reason?: string;
};
export type ValidationError = {
  message: string;
  errors: {
    property: string;
    message: string;
    code: string;
    params?: {
      [key: string]: any;
    };
  }[];
};
export type UpdateTimeTargetingSettings = {
  enabled: boolean;
};
export type UpdateProjectSettings = {
  version: number;
  optimizationStrategy: OptimizationStrategy;
  attributionModel: AttributionModel;
  autoImportCampaigns: boolean;
  createBidModifiers: boolean;
  timeTargeting: UpdateTimeTargetingSettings;
  monthlyBudget: boolean;
  includeVAT: boolean;
  avgOrderValue: number;
  goals: GoalItem[];
  constraints: DateBasedConstraints[];
  groups?: GroupItem[];
  campaigns: CampaignItem[];
};
export type UpdateSharedAccountRequest = {
  data: {
    action: 'RESET_DAILY_BUDGET' | 'FIX_SPEND_MODE';
  };
};
export type ListMeta = {
  total: number;
};
export type Campaign = {
  id: number;
  name: string;
  type: 'DYNAMIC_TEXT_CAMPAIGN' | 'MOBILE_APP_CAMPAIGN' | 'TEXT_CAMPAIGN';
  state: 'ARCHIVED' | 'CONVERTED' | 'ENDED' | 'OFF' | 'ON' | 'SUSPENDED';
  selectable: boolean;
  canManageSearchBid: boolean;
  canManageNetworkBid: boolean;
};
export type Goal = {
  id: number;
  name: string;
};
export type PageMeta = {
  total: number;
  offset: number;
  limit: number;
};
export type ForecastStatus = 'PENDING' | 'RUNNING' | 'SUCCESS' | 'ERROR' | 'CANCELLED';
export type ForecastErrorCode =
  | (
      | 'no_active_campaigns'
      | 'no_solution'
      | 'not_enough_data'
      | 'yandex_token_rejected'
      | 'shared_account_conflict'
      | 'unknown'
    )
  | null;
export type CampaignsCounter = {
  total: number;
  rejected: number;
};
export type SyncCounter = {
  total: number;
  errors: number;
};
export type ForecastItem = {
  id: number;
  forecastDate: string;
  status: ForecastStatus;
  errorCode?: ForecastErrorCode;
  campaignCounts: CampaignsCounter;
  bidCounts: SyncCounter;
  bidModifierCounts: SyncCounter;
};
export type ForecastGroupItem = {
  name: string;
  avgOrderValue: number;
  constraints: Constraint[];
};
export type Statistics = {
  clicks: number;
  conversions: number;
  cost: number;
};
export type ForecastCampaignItem = {
  id: number;
  groupIndex?: number | null;
  rejectionStatus:
    | 'NONE'
    | 'ARCHIVED'
    | 'SUSPENDED'
    | 'ENDED'
    | 'OFF'
    | 'EMPTY'
    | 'INCOMPATIBLE_BIDDING_STRATEGY'
    | 'NOT_ENOUGH_DATA'
    | 'BUDGET_LIMIT_EXCEEDED';
  bidCounts: SyncCounter;
  bidModifierCounts: SyncCounter;
  fact: Statistics;
  forecast: Statistics;
};
export type Forecast = {
  id: number;
  forecastDate: string;
  status: ForecastStatus;
  errorCode?: ForecastErrorCode;
  optimizationStrategy: OptimizationStrategy;
  attributionModel: AttributionModel;
  monthlyBudget: boolean;
  includeVAT: boolean;
  avgOrderValue: number;
  goals: GoalItem[];
  constraints: Constraint[];
  groups: ForecastGroupItem[];
  campaigns: ForecastCampaignItem[];
  campaignCounts: CampaignsCounter;
  bidCounts: SyncCounter;
  bidModifierCounts: SyncCounter;
};
export const {
  useGetProjectSettingsQuery,
  useUpdateProjectSettingsMutation,
  useUpdateSharedAccountMutation,
  useSuspendMutation,
  useUnsuspendMutation,
  useGetCampaignsQuery,
  useGetGoalsQuery,
  useGetForecastsQuery,
  useGetForecastQuery,
} = injectedRtkApi;
