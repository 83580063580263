import { Combobox as FluentCombobox, ComboboxProps, ForwardRefComponent } from '@fluentui/react-components';
import { forwardRef } from 'react';
import { useListboxStyles } from './useListboxStyles';

type ComboboxOptionSelectEventHandler = NonNullable<ComboboxProps['onOptionSelect']>;
type ComboboxChangeEventHandler = NonNullable<ComboboxProps['onChange']>;

export type { ComboboxProps, ComboboxOptionSelectEventHandler, ComboboxChangeEventHandler };

export const Combobox: ForwardRefComponent<ComboboxProps> = forwardRef(({ children, listbox, ...props }, ref) => {
  const listboxSlot = useListboxStyles(listbox);

  return (
    <FluentCombobox ref={ref} listbox={listboxSlot} {...props}>
      {children}
    </FluentCombobox>
  );
});
