import { Switch, SwitchProps } from '@fluentui/react-components';
import { useCallback } from 'react';
import { GetProjectSettings, useSuspendMutation, useUnsuspendMutation } from '../../app/services/optimizer';
import rollbar from '../../rollbar';

export type SuspendSwitchProps = {
  settings: GetProjectSettings;
  className?: string;
};

type SwitchChangeEventHandler = NonNullable<SwitchProps['onChange']>;

type UseMutationTrigger = (projectId: string, suspended: boolean) => { unwrap(): Promise<any> };
type ToggleMutationHookResult = [UseMutationTrigger, boolean];

function useToggleMutation(): ToggleMutationHookResult {
  const [suspend, suspendState] = useSuspendMutation();
  const [unsuspend, unsuspendState] = useUnsuspendMutation();

  const trigger = (projectId: string, suspended: boolean) => {
    if (suspended) {
      return suspend({ project: projectId });
    }

    return unsuspend({ project: projectId });
  };

  return [trigger, suspendState.isUninitialized ? unsuspendState.isLoading : suspendState.isLoading];
}

export const SuspendSwitch = ({ settings, className }: SuspendSwitchProps) => {
  const projectId = settings.projectId as string;
  const [updateSuspendFlag, isUpdating] = useToggleMutation();
  const handleChange = useCallback<SwitchChangeEventHandler>(
    async (e, { checked }) => {
      try {
        await updateSuspendFlag(projectId, !checked).unwrap();
      } catch (err) {
        rollbar.error('Occurred an error during updating suspend state', err as any, {
          projectId,
        });
      }
    },
    [updateSuspendFlag, projectId]
  );

  return (
    <Switch
      className={className}
      label="Выполнять ежедневную оптимизацию кампаний"
      checked={!settings.suspended}
      disabled={isUpdating || settings.version === 1 || settings.campaigns.length === 0}
      onChange={handleChange}
    />
  );
};

export default SuspendSwitch;
