import { makeStyles, tokens, typographyStyles } from '@fluentui/react-components';
import ConstraintView from '../../../components/ConstraintView';
import { ConstraintsValues } from '../form';

export type ConstraintsInfoProps = {
  constraints: ConstraintsValues;
};

const useStyles = makeStyles({
  header: {
    ...typographyStyles.caption1Strong,
    color: tokens.colorNeutralForeground4,
    marginTop: tokens.spacingVerticalSNudge,
  },
});

export const ConstraintsInfo = ({ constraints }: ConstraintsInfoProps) => {
  const classes = useStyles();

  if (constraints.current.length === 0 && constraints.next.length === 0) {
    return null;
  }

  if (constraints.next.length === 0) {
    return (
      <div>
        {constraints.current.map((constraint) => (
          <ConstraintView key={constraint.label} constraint={constraint} />
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className={classes.header}>Текущий месяц</div>
      {constraints.current.map((constraint) => (
        <ConstraintView key={constraint.label} constraint={constraint} />
      ))}
      <div className={classes.header}>Следующий месяц</div>
      {constraints.next.map((constraint) => (
        <ConstraintView key={constraint.label} constraint={constraint} />
      ))}
    </div>
  );
};

export default ConstraintsInfo;
