import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { USER_API_BASE_URL } from '../../config';
import { reauth, signWithToken } from '../utils/auth';
import { retry } from '../utils/retry';

export interface Profile {
  id: string;
  email: string;
}

interface ProfileResponse {
  data: Profile;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: reauth(
    retry(
      fetchBaseQuery({
        baseUrl: USER_API_BASE_URL,
        prepareHeaders: signWithToken,
      })
    )
  ),
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, void>({
      query: () => '/me',
      transformResponse: (response: ProfileResponse) => response.data,
    }),
  }),
});

export const { useGetProfileQuery } = userApi;
