import { Goal, GoalItem } from '../../../app/services/optimizer';

export class SelectedGoal {
  private item: GoalItem;
  private goal: Goal | undefined;

  constructor(selectedItem: GoalItem, goal: Goal | undefined) {
    this.item = selectedItem;
    this.goal = goal;
  }

  get id(): number {
    return this.item.id;
  }

  get name(): string {
    return this.goal?.name || '';
  }

  get weight(): number {
    return this.item.weight;
  }
}
