import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { ReactNode } from 'react';

export type ContentContainerProps = {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  className?: string;
};

const useStyles = makeStyles({
  base: {
    boxSizing: 'border-box',
    width: '100%',
    ...shorthands.margin(tokens.spacingVerticalL, 'auto'),
    ...shorthands.padding('0px', tokens.spacingHorizontalL),
  },
  small: {
    maxWidth: '480px',
  },
  medium: {
    maxWidth: '640px',
  },
  large: {
    maxWidth: '960px',
  },
});

export const ContentContainer = ({ children, className, size = 'medium' }: ContentContainerProps) => {
  const classes = useStyles();

  return <div className={mergeClasses(classes.base, classes[size], className)}>{children}</div>;
};

export default ContentContainer;
