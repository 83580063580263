import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { retry as baseRetry } from '@reduxjs/toolkit/query';

export function retry(baseQuery: BaseQueryFn) {
  return baseRetry(
    async (args, api, extraOptions) => {
      const result = await baseQuery(args, api, extraOptions);
      // @ts-ignore
      const status = result.error?.status;

      if (status >= 400 && status < 500) {
        baseRetry.fail(result.error);
      }

      return result;
    },
    { maxRetries: 5 }
  );
}
