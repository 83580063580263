import { makeStyles, mergeClasses, shorthands, tokens, typographyStyles } from '@fluentui/react-components';
import { ReactNode } from 'react';
import forbiddenImg from '../../assets/forbidden.svg';
import searchImg from '../../assets/search.svg';

export type GenericErrorProps = {
  children: ReactNode;
  className?: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: tokens.colorPaletteRedForeground1,
    boxSizing: 'border-box',
    '& h1': {
      ...typographyStyles.title2,
    },
    '& p': {
      ...shorthands.margin('0.5em', '0px'),
    },
  },
  rootNested: {
    height: '100%',
  },
  noAccess: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px',
    backgroundPositionX: '80%',
    backgroundPositionY: '80%',
    backgroundImage: `url(${forbiddenImg})`,
  },
  notFound: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px',
    backgroundPositionX: '80%',
    backgroundPositionY: '80%',
    backgroundImage: `url(${searchImg})`,
  },
});

export const Error = ({ children, className }: GenericErrorProps) => {
  const classes = useStyles();

  return <div className={mergeClasses(classes.root, className)}>{children}</div>;
};

export default Error;
