import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  Radio,
  RadioGroup,
  RadioGroupProps,
  shorthands,
  Spinner,
  tokens,
} from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import { useCallback, useState } from 'react';
import {
  UpdateSharedAccountAction,
  useGetProjectSettingsQuery,
  useUpdateSharedAccountMutation,
} from '../../app/services/optimizer';
import { getApiErrorCode, getApiErrorReason, isApiError } from '../../app/utils/error';
import rollbar from '../../rollbar';

export type SharedAccountConflictAlertProps = {
  projectId: string;
};

type State = {
  open: boolean;
  submitting: boolean;
  action: UpdateSharedAccountAction | null;
  error: string | null;
};
const defaultState: State = {
  open: false,
  submitting: false,
  action: null,
  error: null,
};

type RadioGroupChangeEventHandler = NonNullable<RadioGroupProps['onChange']>;

const actionOf = (action: UpdateSharedAccountAction) => action;

const useStyles = makeStyles({
  alert: {
    backgroundColor: tokens.colorPaletteYellowBackground2,
    color: tokens.colorPaletteYellowForeground2,
    fontWeight: tokens.fontWeightRegular,
    minHeight: 'auto',
    ...shorthands.borderRadius(tokens.borderRadiusNone),
  },
  dialogContent: {
    marginBottom: '2px', // prevent content scroll
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  dialogAlert: {
    marginTop: '12px',
  },
});

export const SharedAccountConflictAlert = ({ projectId }: SharedAccountConflictAlertProps) => {
  const { data: response } = useGetProjectSettingsQuery({ project: projectId });
  const [fixSharedAccountConflict] = useUpdateSharedAccountMutation();
  const [state, setState] = useState<State>(defaultState);
  const handleDialogTriggerClick = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  }, [setState]);
  const handleOpenChange = useCallback<DialogOpenChangeEventHandler>(
    (e, data) => {
      setState((prevState) => ({
        ...prevState,
        open: data.open,
      }));
    },
    [setState]
  );
  const handleRadioChange = useCallback<RadioGroupChangeEventHandler>(
    (e, data) => {
      setState((prevState) => ({
        ...prevState,
        action: data.value as UpdateSharedAccountAction,
      }));
    },
    [setState]
  );
  const handleFixClick = useCallback(async () => {
    if (!state.action) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      submitting: true,
    }));

    let error = state.error;

    try {
      await fixSharedAccountConflict({
        project: projectId,
        updateSharedAccountRequest: {
          data: {
            action: state.action,
          },
        },
      }).unwrap();
    } catch (err) {
      const errCode = getApiErrorCode(err);
      const errReason = getApiErrorReason(err);

      if (errCode === 403) {
        if (errReason === 'yandex_invalid_token') {
          error = 'Токен для Яндекс.Директ API недействителен.';
        } else if (errReason === 'yandex_insufficient_permissions') {
          error =
            'Недостаточно прав для редактирования общих счетов. Вы можете изменить настройки через веб-интерфейс Директа.';
        } else {
          error = 'Недостаточно прав для выполнения операции.';
        }
      } else if (errCode === 503 && errReason === 'yandex_api_unavailable') {
        error = 'Сервер Яндекс.Директ API временно недоступен. Пожалуйста, попробуйте повторить операцию позже.';
      } else {
        if (isApiError(err)) {
          error = `Не удалось выполнить операцию. Сервер вернул ответ с кодом: ${errCode}`;
        } else {
          error = 'При выполнении операции возникла неизвестная ошибка.';
        }

        rollbar.error('Occurred an error during fixing conflict with the shared account', err as any, {
          projectId,
          action: state.action,
        });
      }
    }

    setState((prevState) => ({
      ...prevState,
      open: !!error,
      submitting: false,
      error,
    }));
  }, [state, setState, fixSharedAccountConflict, projectId]);
  const classes = useStyles();

  if (response && response.data.sharedAccountConflict) {
    return (
      <>
        <Alert
          className={classes.alert}
          intent="warning"
          action={{ children: 'Исправить', onClick: handleDialogTriggerClick }}
        >
          Конфликт с настройками дневного бюджета на уровне «Общего счета». Режим показов общего счета перекрывает режим
          показов кампании.
        </Alert>
        <Dialog open={state.open} onOpenChange={handleOpenChange}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Изменение настроек общего счета</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <p>
                  Режим показов общего счета перекрывает режим показов кампании. Это блокирует использование дневного
                  бюджета на уровне рекламной кампании.
                </p>
                <p>Необходимо изменить настройки общего счета, для управления ставками с помощью оптимизатора.</p>
                <RadioGroup value={state.action || ''} onChange={handleRadioChange}>
                  <Radio value={actionOf('RESET_DAILY_BUDGET')} label="Удалить ограничение для дневного бюджета" />
                  <Radio value={actionOf('FIX_SPEND_MODE')} label="Изменить режим показа объявлений на «стандартный»" />
                </RadioGroup>
                {state.error && (
                  <Alert className={classes.dialogAlert} intent="error">
                    {state.error}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions>
                <Button appearance="primary" onClick={handleFixClick} disabled={!state.action || state.submitting}>
                  {state.submitting ? <Spinner size="tiny" /> : 'Применить'}
                </Button>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Закрыть</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </>
    );
  }

  return null;
};

export default SharedAccountConflictAlert;
