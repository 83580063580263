import {
  Campaign,
  CampaignRejectionStatus,
  Forecast,
  ForecastCampaignItem,
  ForecastErrorCode,
  ForecastGroupItem,
  ForecastStatus,
  Statistics,
} from '../../app/services/optimizer';

export type SummaryStats = Statistics & {
  cpa: number;
};

export type Summary = {
  fact: SummaryStats;
  forecast: SummaryStats;
};

export type GroupStat = {
  name?: string;
  forecast: SummaryStats;
  fact: SummaryStats;
};

export type CampaignItem = Omit<ForecastCampaignItem, 'groupIndex' | 'rejectionStatus' | 'forecast' | 'fact'> & {
  name: string;
  group: ForecastGroupItem | null;
  rejectionStatus: string;
  forecast: SummaryStats;
  fact: SummaryStats;
};

const taskStatusMap: Record<ForecastStatus, string> = {
  PENDING: 'в очереди',
  RUNNING: 'выполняется',
  ERROR: 'ошибка',
  SUCCESS: 'выполнена',
  CANCELLED: 'отменена',
};

export const translateTaskStatus = (status: ForecastStatus): string => taskStatusMap[status] || status;

const rejectionStatusMap: Record<CampaignRejectionStatus, string> = {
  NONE: '',
  ARCHIVED: 'кампания в архиве',
  EMPTY: 'нет активных ключевых фраз',
  ENDED: 'кампания закончилась',
  OFF: 'кампания неактивна',
  SUSPENDED: 'кампания остановлена',
  INCOMPATIBLE_BIDDING_STRATEGY: 'несовместимость стратегии показа',
  NOT_ENOUGH_DATA: 'недостаточно данных для прогноза',
  BUDGET_LIMIT_EXCEEDED: 'достигнут лимит по бюджету',
};

export const translateRejectionStatus = (status: CampaignRejectionStatus): string => {
  if (status in rejectionStatusMap) {
    return rejectionStatusMap[status];
  }

  return status;
};

const errorCodeMap: Record<NonNullable<ForecastErrorCode>, string> = {
  no_active_campaigns: 'нет активных кампаний',
  no_solution: 'нет решения при заданных ограничениях',
  not_enough_data: 'недостаточно данных для выполнения прогноза',
  yandex_token_rejected: 'токен для Яндекс.Директ недействителен',
  shared_account_conflict: 'конфликт с настройками дневного бюджета на уровне «Общего счета»',
  unknown: 'внутренняя ошибка на сервере',
};

export const translateForecastErrorCode = (errorCode: ForecastErrorCode): string => {
  if (errorCode) {
    return errorCodeMap[errorCode] || errorCode;
  }

  return '';
};

export const computeCPA = (stats: Statistics): number => {
  if (stats.conversions > 0) {
    return stats.cost / stats.conversions;
  }

  return 0;
};

export const getSummary = (forecast: Forecast): Summary => {
  return aggregate(forecast.campaigns);
};

export const getGroupsStats = (task: Forecast): GroupStat[] => {
  const res: GroupStat[] = task.groups.map((group, index) => {
    const { forecast, fact } = aggregate(task.campaigns.filter((item) => item.groupIndex === index));

    return {
      name: group.name,
      forecast,
      fact,
    };
  });

  const campaignsWithoutGroup = task.campaigns.filter(
    (item) => item.groupIndex === undefined || item.groupIndex === null
  );

  if (campaignsWithoutGroup.length) {
    const { forecast, fact } = aggregate(campaignsWithoutGroup);

    res.push({
      forecast,
      fact,
    });
  }

  return res;
};

export function buildCampaignListItems(
  items: ForecastCampaignItem[],
  groups: ForecastGroupItem[],
  campaigns: Campaign[]
): CampaignItem[] {
  const findGroup = (index: ForecastCampaignItem['groupIndex']): ForecastGroupItem | null => {
    if (typeof index === 'number' && groups[index]) {
      return groups[index];
    }

    return null;
  };
  const getCampaignName = (id: number): string | undefined => {
    const campaign = campaigns?.find((item) => item.id === id);

    if (campaign) {
      return campaign.name;
    }

    return undefined;
  };

  return items.map((item) => ({
    ...item,
    name: getCampaignName(item.id) || '',
    group: findGroup(item.groupIndex),
    rejectionStatus: translateRejectionStatus(item.rejectionStatus),
    forecast: {
      ...item.forecast,
      cpa: computeCPA(item.forecast),
    },
    fact: {
      ...item.fact,
      cpa: computeCPA(item.fact),
    },
  }));
}

function aggregate(campaigns: ForecastCampaignItem[]): Summary {
  const sum = (total: Statistics, item: Statistics): Statistics => {
    total.clicks += item.clicks;
    total.conversions += item.conversions;
    total.cost += item.cost;

    return total;
  };

  const [fact, forecast] = campaigns.reduce(
    ([fact, forecast], item) => [sum(fact, item.fact), sum(forecast, item.forecast)],
    [
      { clicks: 0, conversions: 0, cost: 0 },
      { clicks: 0, conversions: 0, cost: 0 },
    ]
  );

  return {
    forecast: {
      ...forecast,
      cpa: computeCPA(forecast),
    },
    fact: {
      ...fact,
      cpa: computeCPA(fact),
    },
  };
}
