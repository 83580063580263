import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridProps,
  DataGridRow,
  makeStyles,
  mergeClasses,
  PresenceBadge,
  shorthands,
  TableCellLayout,
  TableColumnDefinition,
  TableRowId,
  tokens,
  Toolbar,
  ToolbarButton,
  Tooltip,
} from '@fluentui/react-components';
import { Add24Filled, ConvertRange24Filled, Delete24Filled } from '@fluentui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Campaign, CampaignItem, translateCampaignState, useGetCampaignsQuery } from '../../../app/services/optimizer';
import EmptyState from '../../../components/EmptyState';
import SearchInput, { InputChangeEventHandler } from '../../../components/SearchInput';
import { FormValues, GroupValues } from '../form';
import { AddCampaignsDialog } from './AddCampaignsDialog';
import { CompatibilityEnum, getCompatibilityDescription, getCompatibilityStatus } from './data';
import { applyFilter, FilterableItem, useFilter } from './filter';
import GroupFilter, { GroupFilterChangeEventHandler } from './GroupFilter';
import MoveToToolbarButton, { GroupSelectEventHandler } from './MoveToToolbarButton';

type ManageCampaignsProps = {
  projectId: string;
};

type DataGridItem = FilterableItem & {
  model: CampaignItem;
  campaign?: Campaign;
  group?: GroupValues;
  compatibility?: CompatibilityEnum;
};

type SelectionChangeEventHandler = NonNullable<DataGridProps['onSelectionChange']>;

const columns: TableColumnDefinition<DataGridItem>[] = [
  createTableColumn({
    columnId: 'id',
    compare: (a, b) => a.model.id - b.model.id,
    renderHeaderCell: () => 'ID',
    renderCell: (item) => item.model.id,
  }),
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => {
      if (!a.campaign || !b.campaign) {
        return 0;
      }

      return a.campaign.name.localeCompare(b.campaign.name);
    },
    renderHeaderCell: () => 'Название',
    renderCell: (item) => item.campaign?.name,
  }),
  createTableColumn({
    columnId: 'status',
    compare: (a, b) => {
      if (!a.campaign || !b.campaign) {
        return 0;
      }

      return a.campaign.state.localeCompare(b.campaign.state);
    },
    renderHeaderCell: () => 'Статус',
    renderCell: (item) => {
      if (!item.campaign) {
        return null;
      }

      return translateCampaignState(item.campaign.state);
    },
  }),
  createTableColumn({
    columnId: 'group',
    compare: (a, b) => (a.group?.name || '').localeCompare(b.group?.name || ''),
    renderHeaderCell: () => 'Группа',
    renderCell: (item) => {
      if (!item.group) {
        return null;
      }

      return item.group.name;
    },
  }),
  createTableColumn({
    columnId: 'compatibility',
    compare: (a, b) => {
      if (!a.compatibility || !b.compatibility) {
        return 0;
      }

      return a.compatibility - b.compatibility;
    },
    renderHeaderCell: () => 'Совместимость',
    renderCell: (item) => {
      if (!item.compatibility) {
        return null;
      }

      return (
        <TableCellLayout
          media={
            <PresenceBadge
              outOfOffice
              status={item.compatibility === CompatibilityEnum.Compatible ? 'available' : 'blocked'}
            />
          }
          description={getCompatibilityDescription(item.compatibility)}
        >
          {item.compatibility === CompatibilityEnum.Compatible ? 'совместима' : 'не совместима'}
        </TableCellLayout>
      );
    },
  }),
];
const getRowId = (item: DataGridItem) => item.model.id;

const useStyles = makeStyles({
  cellId: {
    width: '100px',
    maxWidth: '100px',
  },
  cellStatus: {
    width: '120px',
    maxWidth: '120px',
  },
  cellGroup: {
    width: '15%',
    maxWidth: '15%',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.gap(tokens.spacingHorizontalM),
  },
  filterSearchInput: {
    flexGrow: 0.5,
  },
  empty: {
    marginTop: tokens.spacingVerticalXXXL,
    marginBottom: tokens.spacingVerticalXXXL,
  },
});

const defaultSelectedRowsState = () => new Set<TableRowId>();

export const ManageCampaigns = ({ projectId }: ManageCampaignsProps) => {
  const { data: campaignsResponse } = useGetCampaignsQuery({ project: projectId });
  const { setValue, watch } = useFormContext<FormValues>();

  const [addingCampaigns, setAddingCampaigns] = useState(false);
  const handleAddCampaignsClick = useCallback(() => {
    setAddingCampaigns(true);
  }, [setAddingCampaigns]);
  const handleDialogClose = useCallback(() => {
    setAddingCampaigns(false);
  }, [setAddingCampaigns]);

  const [watchingCampaigns, watchingGroups] = watch(['campaigns', 'groups']);

  const unconnectedCampaigns = useMemo<Campaign[]>(() => {
    const map = new Map<number, CampaignItem>(watchingCampaigns.map((item) => [item.id, item]));
    return (campaignsResponse?.data || []).filter((campaign) => !map.has(campaign.id));
  }, [campaignsResponse, watchingCampaigns]);

  const [selectedRows, setSelectedRows] = useState(defaultSelectedRowsState);
  const [filter, filterMethods] = useFilter();
  const items = useMemo<DataGridItem[]>(() => {
    const campaignsMap = new Map<number, Campaign>(
      (campaignsResponse?.data || []).map((campaign) => [campaign.id, campaign])
    );
    const groupsMap = new Map<GroupValues['id'], GroupValues>(watchingGroups.map((group) => [group.id, group]));

    return watchingCampaigns.map((item): DataGridItem => {
      const campaign = campaignsMap.get(item.id);

      return {
        model: item,
        campaign,
        group: item.groupId ? groupsMap.get(item.groupId) : undefined,
        compatibility: campaign ? getCompatibilityStatus(campaign) : undefined,
        getContent() {
          let content = `${this.model.id}`;

          if (this.campaign) {
            content += ' ' + this.campaign.name;
          }

          if (this.group) {
            content += ' ' + this.group.name;
          }

          return content;
        },
        getGroupId() {
          if (this.model.groupId) {
            return this.model.groupId;
          }

          return undefined;
        },
      };
    });
  }, [campaignsResponse, watchingCampaigns, watchingGroups]);
  const filteredItems = useMemo<DataGridItem[]>(() => applyFilter(items, filter), [items, filter]);

  const handleSelectionChange = useCallback<SelectionChangeEventHandler>(
    (ev, data) => {
      setSelectedRows(data.selectedItems);
    },
    [setSelectedRows]
  );
  const handleDeleteClick = useCallback(() => {
    setValue(
      'campaigns',
      watchingCampaigns.filter((campaign) => !selectedRows.has(campaign.id)),
      { shouldDirty: true }
    );
    setSelectedRows(defaultSelectedRowsState());
  }, [selectedRows, setSelectedRows, setValue, watchingCampaigns]);
  const handleGroupSelect = useCallback<GroupSelectEventHandler>(
    (groupId) => {
      setValue(
        'campaigns',
        watchingCampaigns.map((campaign) => {
          if (selectedRows.has(campaign.id)) {
            return {
              ...campaign,
              groupId,
            };
          }

          return campaign;
        }),
        { shouldDirty: true }
      );
    },
    [selectedRows, setValue, watchingCampaigns]
  );
  const handleCampaignsAdd = useCallback(
    (campaigns: Campaign[], group?: GroupValues) => {
      setValue(
        'campaigns',
        [
          ...watchingCampaigns,
          ...campaigns.map(
            (campaign): CampaignItem => ({
              id: campaign.id,
              groupId: group?.id,
            })
          ),
        ].sort((a, b) => a.id - b.id),
        { shouldDirty: true }
      );
      setAddingCampaigns(false);
    },
    [setAddingCampaigns, setValue, watchingCampaigns]
  );

  const handleQueryShange = useCallback<InputChangeEventHandler>(
    (ev, { value }) => {
      filterMethods.setQuery(value);
    },
    [filterMethods]
  );
  const handleFilterGroupChange = useCallback<GroupFilterChangeEventHandler>(
    (group) => {
      filterMethods.setGroup(group);
    },
    [filterMethods]
  );

  const classes = useStyles();

  return (
    <>
      <div className={classes.filterContainer}>
        <Toolbar>
          <Tooltip
            content="Добавить кампании для управления ставками оптимизатором"
            relationship="description"
            withArrow
          >
            <ToolbarButton
              icon={<Add24Filled />}
              aria-label="Добавить"
              onClick={handleAddCampaignsClick}
              disabled={unconnectedCampaigns.length === 0}
            />
          </Tooltip>
          <MoveToToolbarButton
            icon={<ConvertRange24Filled />}
            aria-label="Переместить"
            groups={watchingGroups}
            disabled={selectedRows.size === 0}
            onGroupSelect={handleGroupSelect}
          />
          <Tooltip content="Отключить выбранные кампании от оптимизатора" relationship="description" withArrow>
            <ToolbarButton
              icon={<Delete24Filled />}
              aria-label="Удалить"
              onClick={handleDeleteClick}
              disabled={selectedRows.size === 0}
            />
          </Tooltip>
        </Toolbar>
        <SearchInput
          className={classes.filterSearchInput}
          placeholder="Поиск по списку"
          value={filter.query}
          onChange={handleQueryShange}
        />
        <GroupFilter groups={watchingGroups} value={filter.group} onChange={handleFilterGroupChange} />
      </div>
      {addingCampaigns && (
        <AddCampaignsDialog
          campaigns={unconnectedCampaigns}
          groups={watchingGroups}
          onAdd={handleCampaignsAdd}
          onClose={handleDialogClose}
        />
      )}
      {filteredItems.length === 0 && filteredItems.length !== items.length && (
        <EmptyState
          className={classes.empty}
          primaryMessage="Не найдено ни одной кампании"
          secondaryMessage="Попробуйте изменить настройки фильтра."
        />
      )}
      {filteredItems.length === 0 && items.length === 0 && (
        <EmptyState className={classes.empty} primaryMessage="Ещё ни одной кампании не добавлено" />
      )}
      {filteredItems.length > 0 && (
        <DataGrid
          columns={columns}
          items={filteredItems}
          sortable
          selectionMode="multiselect"
          selectedItems={selectedRows}
          subtleSelection
          getRowId={getRowId}
          onSelectionChange={handleSelectionChange}
        >
          <DataGridHeader>
            <DataGridRow selectionCell={{ 'aria-label': 'Выбор всех строк' }}>
              {({ renderHeaderCell, columnId }) => (
                <DataGridHeaderCell
                  className={mergeClasses(
                    columnId === 'id' && classes.cellId,
                    columnId === 'status' && classes.cellStatus,
                    columnId === 'group' && classes.cellGroup
                  )}
                >
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<DataGridItem>>
            {({ item, rowId }) => (
              <DataGridRow<DataGridItem> key={rowId}>
                {({ renderCell, columnId }) => (
                  <DataGridCell
                    className={mergeClasses(
                      columnId === 'id' && classes.cellId,
                      columnId === 'status' && classes.cellStatus,
                      columnId === 'group' && classes.cellGroup
                    )}
                  >
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      )}
    </>
  );
};

export default ManageCampaigns;
