import { create, each, enforce, test } from 'vest';
import { GroupValues } from '../form';

export const validationSuite = create((data: GroupValues) => {
  test('name', 'Поле не может быть пустым', () => {
    enforce(data.name.trim()).isNotEmpty();
  });

  each(data.constraints.current, (constraint, index) => {
    test(
      `constraints.current.${index}.max`,
      'Значение должно быть положительным числом',
      () => {
        enforce(constraint.max).greaterThan(0);
      },
      constraint.label
    );
  });

  each(data.constraints.next, (constraint, index) => {
    test(
      `constraints.next.${index}.max`,
      'Значение должно быть положительным числом',
      () => {
        enforce(constraint.max).greaterThan(0);
      },
      constraint.label
    );
  });
});
