import { ConstraintLabel, getConstraintLabels } from '../../app/services/optimizer';

export const getSelectableConstraintLabels = (selectedLabels: ConstraintLabel[]): ConstraintLabel[] => {
  let freeLabels = getConstraintLabels().filter((label) => !selectedLabels.includes(label));

  if (selectedLabels.includes('CRR')) {
    freeLabels = freeLabels.filter((label) => label !== 'CPA');
  }

  if (selectedLabels.includes('CPA')) {
    freeLabels = freeLabels.filter((label) => label !== 'CRR');
  }

  return freeLabels;
};
