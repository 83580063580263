import { Campaign } from '../../../app/services/optimizer';

export enum CompatibilityEnum {
  Compatible = 1,
  MultipleNetworkTypesNotSupported = 2,
  ChangeBidNotAllowed = 3,
}

export function getCompatibilityStatus(campaign: Campaign): CompatibilityEnum {
  if (campaign.canManageSearchBid && campaign.canManageNetworkBid) {
    return CompatibilityEnum.MultipleNetworkTypesNotSupported;
  }

  if (campaign.canManageSearchBid || campaign.canManageNetworkBid) {
    return CompatibilityEnum.Compatible;
  }

  return CompatibilityEnum.ChangeBidNotAllowed;
}

export function getCompatibilityDescription(compatibility: CompatibilityEnum): string | undefined {
  if (compatibility === CompatibilityEnum.MultipleNetworkTypesNotSupported) {
    return 'Одновременное управление ставкой для поиска и РСЯ не поддерживается';
  }

  if (compatibility === CompatibilityEnum.ChangeBidNotAllowed) {
    return 'Управление ставкой недоступно. Возможно, используется одна из автоматических стратегий Яндекс.Директа';
  }
}
