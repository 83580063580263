import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { camelizeKeys } from 'humps';
import { PROJECT_API_BASE_URl } from '../../config';
import { AppDispatch } from '../store';
import { reauth, signWithToken } from '../utils/auth';
import { retry } from '../utils/retry';

export type ProjectItem = {
  id: string;
  name: string;
  archived: boolean;
  createdAt: string;
};

type IntegrationStatus = 'unconnected' | 'connected' | 'revoked' | 'pending' | 'disabled';

export type Project = ProjectItem & {
  yandexDirect: {
    authLogin: string | null;
    clientLogin: string | null;
    clientName: string | null;
    status: IntegrationStatus;
  };
  googleAnalytics: {
    authLogin: string | null;
    accountId: string | null;
    webPropertyId: string | null;
    status: IntegrationStatus;
  };
  user: {
    role: 'OWNER' | 'ADMIN' | 'CHIEF' | 'ACCOUNT';
  };
};

type ApiProjectsResponse = {
  data: ProjectItem[];
};

type ApiProjectResponse = {
  data: Project;
};

export const projectApi = createApi({
  reducerPath: 'projectApi',
  tagTypes: ['Project'],
  baseQuery: reauth(
    retry(
      fetchBaseQuery({
        baseUrl: PROJECT_API_BASE_URl,
        prepareHeaders: signWithToken,
      })
    )
  ),
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectItem[], void>({
      query: () => ({
        url: 'projects',
        params: { limit: 100 },
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Project' as const, id })), { type: 'Project', id: 'LIST' }]
          : [{ type: 'Project', id: 'LIST' }],
      transformResponse: (res: ApiProjectsResponse) => camelizeKeys(res.data) as ProjectItem[],
    }),
    getProject: builder.query<Project, string>({
      query: (id) => `projects/${id}`,
      providesTags: (result, error, id) => [{ type: 'Project', id }],
      transformResponse: (res: ApiProjectResponse) => camelizeKeys(res.data) as Project,
    }),
  }),
});

export const { useGetProjectQuery, useGetProjectsQuery } = projectApi;

export async function getProject(dispatch: AppDispatch, projectId: string): Promise<Project> {
  const promise = dispatch(projectApi.endpoints.getProject.initiate(projectId));
  const res = await promise;
  promise.unsubscribe();

  if (res.isSuccess) {
    return res.data;
  }

  return Promise.reject('Could not load project');
}
