import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
  CounterBadge,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { vestResolver } from '@hookform/resolvers/vest';
import { useCallback, useEffect } from 'react';
import { FormProvider, FormState, useForm } from 'react-hook-form';
import {
  AttributionModel,
  getAttributionModels,
  getOptimizationStrategies,
  GetProjectSettings,
  OptimizationStrategy,
  translateAttributionModel,
  translateOptimizationStrategy,
  useGetGoalsQuery,
  useUpdateProjectSettingsMutation,
} from '../../app/services/optimizer';
import { isApiError } from '../../app/utils/error';
import { CheckboxField, DropdownField, DropdownOption, InputField } from '../../components/form';
import rollbar from '../../rollbar';
import { nameof } from '../../utils/nameof';
import { ConstraintsField } from './ConstraintsField';
import { BudgetMode, FormValues, toForm, toModel } from './form';
import { GoalsField } from './GoalsField';
import { ManageCampaigns } from './ManageCampaigns';
import { ManageGroups } from './ManageGroups';
import { handleApiError } from './serverErrors';
import { validationSuite } from './validation';

export type SettingsFormProps = {
  className?: string;
  settings: GetProjectSettings;
};

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.margin(0, tokens.spacingHorizontalXXS),
  },
  formFields: {
    flexGrow: 1,
  },
  formActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'sticky',
    bottom: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    marginTop: tokens.spacingVerticalM,
    ...shorthands.padding(tokens.spacingVerticalS, '18px'),
    ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralBackground1Selected),
    '& > :not(:last-child)': {
      marginRight: tokens.spacingHorizontalS,
    },
    '& > .fui-Alert': {
      flexGrow: 1,
    },
  },
  field: {
    marginBottom: tokens.spacingVerticalM,
  },
  fieldCheckbox: {
    marginBottom: 0,
  },
  withInfoButton: {
    display: 'flex',
    alignItems: 'center',
  },
  panelCommon: {
    marginLeft: '40px',
    marginRight: '40px',
    maxWidth: '740px',
  },
  panelGroups: {
    marginLeft: '40px',
    marginRight: '40px',
  },
  goalsFieldLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  badge: {
    marginTop: '3px',
    marginLeft: tokens.spacingHorizontalS,
  },
});

const budgetModeOptions: DropdownOption<BudgetMode>[] = [
  { value: '28_DAYS', text: '28 дней' },
  { value: 'MONTHLY', text: 'календарный месяц' },
];

const attributionModelOptions: DropdownOption<AttributionModel>[] = getAttributionModels().map((attributionModel) => ({
  text: translateAttributionModel(attributionModel),
  value: attributionModel,
}));

const optimizationStrategyOptions: DropdownOption<OptimizationStrategy>[] = getOptimizationStrategies().map(
  (strategy) => ({
    text: translateOptimizationStrategy(strategy),
    value: strategy,
  })
);

function resolveRootError(formState: FormState<FormValues>): string | undefined {
  const { errors } = formState;

  if (errors.root) {
    return errors.root.message as string;
  }

  if ((errors as any)._root) {
    return (errors as any)._root.message;
  }

  if (!formState.isValid && Object.keys(errors).length > 0) {
    return 'Форма содержит ошибки';
  }
}

export const SettingsForm = ({ settings, className }: SettingsFormProps) => {
  const [updateSettings] = useUpdateProjectSettingsMutation();
  const { data: goalsResponse } = useGetGoalsQuery(
    { project: settings.projectId! },
    { refetchOnMountOrArgChange: true }
  );
  const formMethods = useForm<FormValues>({
    defaultValues: toForm(settings),
    resolver: vestResolver(validationSuite),
  });
  const { control, handleSubmit, watch, getValues, reset, formState, setError } = formMethods;

  useEffect(() => {
    if (
      settings.projectId !== getValues('projectId') ||
      (!formState.isDirty && settings.version !== getValues('version'))
    ) {
      reset(toForm(settings));
    }
  }, [settings, getValues, reset, formState.isDirty]);

  const [watchingCampaigns, watchingGroups] = watch(['campaigns', 'groups']);

  const onSubmit = useCallback(
    async (formData: FormValues) => {
      try {
        const response = await updateSettings({
          project: formData.projectId,
          body: { data: toModel(formData) },
        }).unwrap();

        reset(toForm(response.data));
      } catch (err) {
        if (isApiError(err)) {
          if (handleApiError(err, setError)) {
            return;
          }

          let message = `Не удалось сохранить изменения. Сервер вернул ответ с кодом: ${err.status}`;

          if (err.data.reason) {
            message += ` (${err.data.reason})`;
          }

          setError('root', {
            type: 'serverError',
            message,
          });
        } else {
          setError('root', {
            message: 'При обработке формы возникла неизвестная ошибка.',
          });
        }

        rollbar.error('Update settings failed', err as any);
      }
    },
    [updateSettings, setError, reset]
  );
  const handleCancelClick = useCallback(() => {
    reset(toForm(settings));
  }, [settings, reset]);

  const classes = useStyles();

  const rootError = resolveRootError(formState);

  return (
    <FormProvider {...formMethods}>
      <form className={mergeClasses(classes.form, className)} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Accordion className={classes.formFields} defaultOpenItems="common">
          <AccordionItem value="common">
            <AccordionHeader as="h2" size="extra-large">
              Общие настройки
            </AccordionHeader>
            <AccordionPanel className={classes.panelCommon}>
              <CheckboxField
                className={mergeClasses(classes.field, classes.fieldCheckbox)}
                name={nameof<FormValues>('autoImportCampaigns')}
                label="Автоматически подключать рекламные кампании к оптимизатору"
              />
              <CheckboxField
                className={mergeClasses(classes.field, classes.fieldCheckbox)}
                name={nameof<FormValues>('createBidModifiers')}
                label="Автоматически добавлять корректировки к кампаниям"
                hint="В подключенные к оптимизатору кампании будут добавляться корректировки по устройствам, полу и возрасту, платежеспособности и эксклюзивному размещению."
                hintAppearance="info-button"
              />
              <CheckboxField
                className={mergeClasses(classes.field, classes.fieldCheckbox)}
                name="timeTargeting.enabled"
                label="Разрешить управление часовыми корректировками"
                hint="Часовые корректировки ставок будут обновляться автоматически, без изменения расписания показов."
                hintAppearance="info-button"
              />
              <CheckboxField
                className={mergeClasses(classes.field, classes.fieldCheckbox)}
                name={nameof<FormValues>('includeVAT')}
                label="с учётом НДС"
                hint="Если опция активирована, значения денежных показателей будут с учётом НДС. Статистика, средний чек, ограничения и пр."
                hintAppearance="info-button"
              />
              <DropdownField
                className={classes.field}
                name={nameof<FormValues>('optimizationStrategy')}
                label="Стратегия оптимизации"
                options={optimizationStrategyOptions}
              />
              <DropdownField
                className={classes.field}
                name={nameof<FormValues>('attributionModel')}
                label="Модель атрибуции"
                options={attributionModelOptions}
              />
              <GoalsField className={classes.field} name="goals" label="Цели" options={goalsResponse?.data || []} />
              <InputField
                className={classes.field}
                type="number"
                name={nameof<FormValues>('avgOrderValue')}
                label="Средний чек"
              />
              <DropdownField
                className={classes.field}
                name={nameof<FormValues>('budgetMode')}
                label="Период учета расхода"
                options={budgetModeOptions}
                hint="Возможен перерасход около 3%"
              />
              <ConstraintsField className={classes.field} control={control} name="constraints" label="Ограничения" />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="groups">
            <AccordionHeader as="h2" size="extra-large">
              Группы
              <CounterBadge
                className={classes.badge}
                count={watchingGroups.length}
                appearance="filled"
                color="informative"
              />
            </AccordionHeader>
            <AccordionPanel className={classes.panelGroups}>
              <ManageGroups />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="campaigns">
            <AccordionHeader as="h2" size="extra-large">
              Кампании
              <CounterBadge
                className={classes.badge}
                count={watchingCampaigns.length}
                appearance="filled"
                color="informative"
              />
            </AccordionHeader>
            <AccordionPanel className={classes.panelGroups}>
              <ManageCampaigns projectId={settings.projectId!} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <div className={classes.formActions}>
          <Button type="submit" appearance="primary" disabled={formState.isSubmitting || !formState.isDirty}>
            Сохранить
          </Button>
          <Button onClick={handleCancelClick} disabled={formState.isSubmitting || !formState.isDirty}>
            Отмена
          </Button>
          {rootError && <Alert intent="error">{rootError}</Alert>}
        </div>
      </form>
    </FormProvider>
  );
};

export default SettingsForm;
