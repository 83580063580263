import { format } from 'date-fns';

export function toUTCDate(date: Date): string {
  const offset = getTimezoneOffsetInMilliseconds(date);

  return format(date.getTime() + offset, 'yyyy-MM-dd');
}

export function getFirstDayOfNextMonth(date?: Date): Date {
  const nextMonth = date ? new Date(date.getTime()) : new Date();
  nextMonth.setUTCDate(1);
  nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1);

  return nextMonth;
}

const datePattern = /^(\d{4})-(0[1-9]|11|12)-(0[1-9]|[1-2][0-9]|30|31)$/;

export function parseDate(date: string): Date {
  if (!datePattern.test(date)) {
    throw Error(`Invalid format of date "${date}"`);
  }

  return new Date(`${date}T00:00:00Z`);
}

function getTimezoneOffsetInMilliseconds(date: Date): number {
  return date.getTimezoneOffset() * 60 * 1000;
}
