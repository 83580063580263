import {
  Constraint,
  DateBasedConstraints,
  GetProjectSettings,
  GroupItem,
  UpdateProjectSettings,
} from '../../app/services/optimizer';
import { getFirstDayOfNextMonth, toUTCDate } from '../../utils/date';

export type ConstraintsValues = {
  current: Constraint[];
  next: Constraint[];
};

export type GroupValues = {
  id: string;
  name: string;
  avgOrderValue: number;
  constraints: ConstraintsValues;
};

export type BudgetMode = '28_DAYS' | 'MONTHLY';

export type FormValues = Omit<UpdateProjectSettings, 'constraints' | 'groups' | 'monthlyBudget'> & {
  projectId: string;
  budgetMode: BudgetMode;
  groups: GroupValues[];
  constraints: ConstraintsValues;
};

export function toForm(settings: GetProjectSettings): FormValues {
  return {
    ...settings,
    projectId: settings.projectId!,
    budgetMode: settings.monthlyBudget ? 'MONTHLY' : '28_DAYS',
    constraints: constraintsToForm(settings.constraints),
    groups: groupsToForm(settings.groups || []),
    timeTargeting: {
      enabled: settings.timeTargeting.enabled,
    },
  };
}

export function toModel(data: FormValues): UpdateProjectSettings {
  return {
    version: data.version,
    optimizationStrategy: data.optimizationStrategy,
    attributionModel: data.attributionModel,
    goals: data.goals,
    avgOrderValue: data.avgOrderValue,
    includeVAT: data.includeVAT,
    timeTargeting: data.timeTargeting,
    createBidModifiers: data.createBidModifiers,
    autoImportCampaigns: data.autoImportCampaigns,
    monthlyBudget: data.budgetMode === 'MONTHLY',
    campaigns: data.campaigns,
    constraints: constraintsToModel(data.constraints),
    groups: groupsToModel(data.groups),
  };
}

function getToday(): string {
  return toUTCDate(new Date());
}

function getNextMonth(): string {
  return toUTCDate(getFirstDayOfNextMonth());
}

function constraintsToForm(constraints: DateBasedConstraints[]): ConstraintsValues {
  if (constraints.length === 0) {
    return {
      current: [],
      next: [],
    };
  }

  const sortedConstraintSets = [...constraints].sort((a, b) => a.notBefore.localeCompare(b.notBefore));
  const today = getToday();

  const before = sortedConstraintSets.filter((item) => item.notBefore <= today);
  const after = sortedConstraintSets.filter((item) => item.notBefore > today);

  return {
    current: before.length > 0 ? before[before.length - 1].constraints : [],
    next: after.length > 0 ? after[0].constraints : [],
  };
}

function constraintsToModel(constraints: ConstraintsValues): DateBasedConstraints[] {
  const result: DateBasedConstraints[] = [];

  if (constraints.current.length > 0) {
    result.push({
      notBefore: getToday(),
      constraints: constraints.current,
    });
  }

  if (constraints.next.length > 0) {
    result.push({
      notBefore: getNextMonth(),
      constraints: constraints.next,
    });
  }

  return result;
}

function groupsToForm(groups: GroupItem[]): GroupValues[] {
  return groups.map((group) => ({
    ...group,
    constraints: constraintsToForm(group.constraints),
  }));
}

function groupsToModel(groups: GroupValues[]): GroupItem[] {
  return groups.map((group) => ({
    ...group,
    constraints: constraintsToModel(group.constraints),
  }));
}
