import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { FormatNumberOptions, useIntl } from 'react-intl';
import { currencyOptions, integerOptions } from '../../../utils/intl';
import { SummaryStats } from '../data';
import Stat from './Stat';

export type SummaryProps = {
  className?: string;
  forecast: SummaryStats;
  fact: SummaryStats;
  dummy?: boolean;
};

type SummaryItemProps = {
  label: string;
  forecast: number;
  fact: number;
  dummy: boolean;
  formatOptions?: FormatNumberOptions;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    width: 'auto',
    height: 'auto',
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
    columnGap: tokens.spacingHorizontalM,
    boxSizing: 'border-box',
    boxShadow: tokens.shadow4,
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    '& > *': {
      flexShrink: 1,
      textOverflow: 'ellipsis',
    },
    '& .stat': {
      textAlign: 'right',
    },
  },
});

export const Summary = ({ className, forecast, fact, dummy }: SummaryProps) => {
  const classes = useStyles();

  return (
    <div className={mergeClasses(classes.root, className)}>
      <SummaryItem
        label="Клики"
        forecast={forecast.clicks}
        fact={fact.clicks}
        dummy={dummy ?? false}
        formatOptions={integerOptions}
      />
      <SummaryItem
        label="Конверсии"
        forecast={forecast.conversions}
        fact={fact.conversions}
        dummy={dummy ?? false}
        formatOptions={integerOptions}
      />
      <SummaryItem
        label="Цена конверсии"
        forecast={forecast.cpa}
        fact={fact.cpa}
        dummy={dummy ?? false}
        formatOptions={currencyOptions}
      />
      <SummaryItem
        label="Расход"
        forecast={forecast.cost}
        fact={fact.cost}
        dummy={dummy ?? false}
        formatOptions={currencyOptions}
      />
    </div>
  );
};

const SummaryItem = ({ forecast, fact, label, dummy, formatOptions }: SummaryItemProps) => {
  const intl = useIntl();

  if (dummy) {
    return <Stat label={label} number="--" />;
  }

  return (
    <Stat
      label={label}
      number={intl.formatNumber(forecast, formatOptions)}
      description={
        <>
          За предыдущие 28 дней: <b>{intl.formatNumber(fact, formatOptions)}</b>
        </>
      }
    />
  );
};

export default Summary;
