import { Dropdown as FluentDropdown, DropdownProps, ForwardRefComponent } from '@fluentui/react-components';
import { forwardRef } from 'react';
import { useListboxStyles } from './useListboxStyles';

type DropdownOptionSelectEventHandler = NonNullable<DropdownProps['onOptionSelect']>;

export type { DropdownProps, DropdownOptionSelectEventHandler };

export const Dropdown: ForwardRefComponent<DropdownProps> = forwardRef(({ children, listbox, ...props }, ref) => {
  const listboxSlot = useListboxStyles(listbox);

  return (
    <FluentDropdown ref={ref} listbox={listboxSlot} {...props}>
      {children}
    </FluentDropdown>
  );
});
