import { makeResetStyles, makeStyles, mergeClasses, tokens, typographyStyles } from '@fluentui/react-components';
import { AppsListRegular } from '@fluentui/react-icons';

export type EmptyStateProps = {
  className?: string;
  primaryMessage: string;
  secondaryMessage?: string;
};

const useRootClassName = makeResetStyles({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  maxWidth: '420px',
  margin: '0 auto',
});

const useIconWrapperClassName = makeResetStyles({
  padding: tokens.spacingVerticalL,
  border: `${tokens.strokeWidthThick} solid ${tokens.colorNeutralForegroundDisabled}`,
  borderRadius: tokens.borderRadiusCircular,
});

const useStyles = makeStyles({
  icon: {
    color: tokens.colorNeutralForegroundDisabled,
    width: '86px',
    height: '86px',
    marginBottom: '-2px',
  },
  primaryMessage: {
    ...typographyStyles.subtitle2,
    color: tokens.colorNeutralForegroundDisabled,
    marginTop: tokens.spacingVerticalL,
    textAlign: 'center',
  },
  secondaryMessage: {
    ...typographyStyles.body1,
    color: tokens.colorNeutralForegroundDisabled,
    marginTop: tokens.spacingVerticalS,
    textAlign: 'center',
  },
});

export const EmptyState = ({ className, primaryMessage, secondaryMessage }: EmptyStateProps) => {
  const rootClassName = useRootClassName();
  const iconWrapperClassName = useIconWrapperClassName();
  const classes = useStyles();

  return (
    <div className={mergeClasses(rootClassName, className)}>
      <div className={iconWrapperClassName}>
        <AppsListRegular className={classes.icon} />
      </div>
      <div className={classes.primaryMessage}>{primaryMessage}</div>
      {secondaryMessage && <div className={classes.secondaryMessage}>{secondaryMessage}</div>}
    </div>
  );
};

export default EmptyState;
