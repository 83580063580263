import { Option } from '@fluentui/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Combobox, ComboboxChangeEventHandler, ComboboxOptionSelectEventHandler } from '../../../components/Combobox';
import { GroupValues } from '../form';

export type GroupSelectEventHandler = (group?: GroupValues) => void;

export type GroupDropdownProps = {
  groups: GroupValues[];
  selectedGroup?: GroupValues;
  placeholder?: string;
  onSelect: GroupSelectEventHandler;
};

export const GroupDropdown = ({ groups, selectedGroup, onSelect, placeholder }: GroupDropdownProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(selectedGroup?.name || '');
  }, [selectedGroup, setValue]);

  const matchingGroups = useMemo<GroupValues[]>(() => {
    if (!value) {
      return groups;
    }

    const query = value.toLowerCase();

    return groups.filter((group) => group.name.toLowerCase().indexOf(query) > -1);
  }, [groups, value]);

  const handleChange = useCallback<ComboboxChangeEventHandler>(
    ({ target }) => {
      setValue(target.value);
    },
    [setValue]
  );
  const handleOptionSelect = useCallback<ComboboxOptionSelectEventHandler>(
    (ev, { optionText, optionValue }) => {
      setValue(optionText || '');

      onSelect(groups.find((group) => group.id === optionValue));
    },
    [groups, onSelect, setValue]
  );

  return (
    <Combobox
      placeholder={placeholder}
      onChange={handleChange}
      onOptionSelect={handleOptionSelect}
      selectedOptions={selectedGroup ? [selectedGroup.id] : []}
      value={value}
      freeform
    >
      {matchingGroups.map((group) => (
        <Option key={group.id} value={group.id}>
          {group.name}
        </Option>
      ))}
      {matchingGroups.length === 0 ? (
        <Option key="no-results" text="" disabled>
          Нет результатов
        </Option>
      ) : null}
    </Combobox>
  );
};

export default GroupDropdown;
