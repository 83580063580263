import { Option, OptionGroup } from '@fluentui/react-components';
import { useCallback, useMemo } from 'react';
import { Dropdown, DropdownOptionSelectEventHandler } from '../../../components/Combobox';
import { GroupValues } from '../form';
import { GroupFilterValue } from './filter';

export type GroupFilterChangeEventHandler = (value: GroupFilterValue) => void;

export type GroupFilterProps = {
  groups: GroupValues[];
  value: GroupFilterValue;
  onChange: GroupFilterChangeEventHandler;
  className?: string;
};

export const GroupFilter = ({ groups, value, onChange, className }: GroupFilterProps) => {
  const sortedGroups = useMemo(() => groups.sort((a, b) => a.name.localeCompare(b.name)), [groups]);
  const handleOptionSelect = useCallback<DropdownOptionSelectEventHandler>(
    (ev, { optionText }) => {
      if (optionText === 'Все') {
        onChange(undefined);
      } else if (optionText === 'Без группы') {
        onChange('WITHOUT_GROUP');
      } else {
        const group = groups.find((group) => group.name === optionText);

        if (group) {
          onChange(group.id);
        }
      }
    },
    [onChange, groups]
  );
  const selectedOptions: string[] = [];

  if (value === 'WITHOUT_GROUP') {
    selectedOptions.push('Без группы');
  } else if (value) {
    const group = groups.find((group) => group.id === value);

    if (group) {
      selectedOptions.push(group.name);
    }
  }

  return (
    <Dropdown
      className={className}
      placeholder="Фильтр по группе"
      onOptionSelect={handleOptionSelect}
      selectedOptions={selectedOptions}
    >
      <Option>Все</Option>
      <Option>Без группы</Option>
      {sortedGroups.length > 0 && (
        <OptionGroup label="Группы">
          {sortedGroups.map((group) => (
            <Option key={group.id}>{group.name}</Option>
          ))}
        </OptionGroup>
      )}
    </Dropdown>
  );
};

export default GroupFilter;
