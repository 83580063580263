import { makeStyles, mergeClasses } from '@fluentui/react-components';
import forbiddenImg from '../../assets/forbidden.svg';
import Error from './Error';

export type NoAccessProps = {
  className?: string;
};

const useStyles = makeStyles({
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px',
    backgroundPositionX: '80%',
    backgroundPositionY: '80%',
    backgroundImage: `url(${forbiddenImg})`,
  },
});

export const NoAccess = ({ className }: NoAccessProps) => {
  const classes = useStyles();

  return (
    <Error className={mergeClasses(classes.image, className)}>
      <h1>Ошибка доступа</h1>
      <p>К сожалению, у Вас недостаточно прав для работы с данным ресурсом.</p>
    </Error>
  );
};
