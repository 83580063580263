import { Enter } from '@fluentui/keyboard-keys';
import { Button, Field, Input, InputProps, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import React, { useCallback, useState } from 'react';
import { SignInRequest, useSignInMutation } from '../../app/services/auth';
import { getApiErrorCode } from '../../app/utils/error';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('15px'),
    '& .fui-Field__label': {
      fontWeight: tokens.fontWeightSemibold,
    },
  },
});

export const SignInForm = () => {
  const [signIn, { isLoading }] = useSignInMutation();
  const [error, setError] = useState<string>('');
  const [formState, setFormState] = useState<SignInRequest>({
    email: '',
    password: '',
  });

  const handleChange: InputProps['onChange'] = ({ currentTarget: { name } }, { value }) =>
    setFormState((prev) => ({ ...prev, [name]: value }));

  const handleSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }

    try {
      await signIn(formState).unwrap();
    } catch (err) {
      const status = getApiErrorCode(err);

      if (status >= 500) {
        setError('Сервис временно недоступен. Пожалуйста, попробуйте повторить запрос позже.');
      } else if (status === 400 || status === 422) {
        setError('Неверный e-mail или пароль.');
      } else {
        setError('При обработке формы возникла неизвестная ошибка.');
      }
    }
  }, [formState, setError, signIn, isLoading]);
  const handleKeyUp = useCallback(
    async (ev: React.KeyboardEvent) => {
      if (ev.key === Enter) {
        await handleSubmit();
      }
    },
    [handleSubmit]
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Field label="E-mail">
        <Input type="email" name="email" onChange={handleChange} />
      </Field>
      <Field label="Пароль">
        <Input type="password" name="password" onChange={handleChange} onKeyUp={handleKeyUp} />
      </Field>
      <Button appearance="primary" onClick={handleSubmit} disabled={isLoading}>
        Войти
      </Button>
      {error && <Alert intent="error">{error}</Alert>}
    </div>
  );
};

export default SignInForm;
