import { FormatNumberOptions } from 'react-intl';

export const integerOptions: FormatNumberOptions = {
  maximumFractionDigits: 0,
};

export const currencyOptions: FormatNumberOptions = {
  style: 'currency',
  currency: 'RUB',
};
