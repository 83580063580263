import { FieldProps } from '@fluentui/react-components';
import { FieldValues } from 'react-hook-form/dist/types';
import type { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Transform } from './types';

export function enhanceFieldProps(props: FieldProps, fieldState: ControllerFieldState): FieldProps {
  if (fieldState.error) {
    return {
      ...props,
      validationState: 'error',
      validationMessage: fieldState.error.message,
    };
  }

  return props;
}

export function getInputValue<TFieldValues extends FieldValues = FieldValues>(
  field: ControllerRenderProps<TFieldValues>,
  transform?: Transform
): any {
  if (transform) {
    return transform.input(field.value);
  }

  return field.value;
}

const nullTransform = (value: any) => value;

export function getTransformOutputFunction(transform?: Transform): Transform['output'] {
  return transform?.output ?? nullTransform;
}

export function mergeCallbacks<Args extends unknown[]>(
  callback1: ((...args: Args) => void) | undefined,
  callback2: ((...args: Args) => void) | undefined
) {
  return (...args: Args) => {
    callback1?.(...args);
    callback2?.(...args);
  };
}
