import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  mergeClasses,
  TableColumnDefinition,
} from '@fluentui/react-components';
import { FormattedNumber } from 'react-intl';
import { ForecastGroupItem } from '../../../../app/services/optimizer';
import ConstraintView from '../../../../components/ConstraintView';
import { currencyOptions } from '../../../../utils/intl';

export type GroupsListProps = {
  groups: ForecastGroupItem[];
};

const columns: TableColumnDefinition<ForecastGroupItem>[] = [
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => a.name.localeCompare(b.name),
    renderHeaderCell: () => 'Название',
    renderCell: (item) => item.name,
  }),
  createTableColumn({
    columnId: 'avgOrderValue',
    compare: (a, b) => a.avgOrderValue - b.avgOrderValue,
    renderHeaderCell: () => 'Средний чек',
    renderCell: (item) => {
      if (item.avgOrderValue > 0) {
        return <FormattedNumber value={item.avgOrderValue} {...currencyOptions} />;
      }

      return '—';
    },
  }),
  createTableColumn({
    columnId: 'constraints',
    renderHeaderCell: () => 'Ограничения',
    renderCell: (item) => {
      return (
        <div>
          {item.constraints.map((constraint) => (
            <ConstraintView key={constraint.label} constraint={constraint} />
          ))}
        </div>
      );
    },
  }),
];

const useStyles = makeStyles({
  cellAvgOrderValue: {
    width: '150px',
    maxWidth: '150px',
  },
});

export const GroupsList = ({ groups }: GroupsListProps) => {
  const classes = useStyles();

  return (
    <DataGrid columns={columns} items={groups} sortable>
      <DataGridHeader>
        <DataGridRow>
          {({ columnId, renderHeaderCell }) => (
            <DataGridHeaderCell className={mergeClasses(columnId === 'avgOrderValue' && classes.cellAvgOrderValue)}>
              {renderHeaderCell()}
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<ForecastGroupItem>>
        {({ item, rowId }) => (
          <DataGridRow<ForecastGroupItem> key={rowId}>
            {({ columnId, renderCell }) => (
              <DataGridCell className={mergeClasses(columnId === 'avgOrderValue' && classes.cellAvgOrderValue)}>
                {renderCell(item)}
              </DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};

export default GroupsList;
