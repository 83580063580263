import { create, each, enforce, test } from 'vest';
import { Constraint, translateConstraintLabel } from '../../app/services/optimizer';
import { nameof } from '../../utils/nameof';
import { FormValues } from './form';

function hasCPAConstraint(list: Constraint[]): boolean {
  return list.find((item) => item.label === 'CPA') !== undefined;
}

function hasCRRConstraint(list: Constraint[]): boolean {
  return list.find((item) => item.label === 'CRR') !== undefined;
}

export const validationSuite = create((data: FormValues) => {
  test(nameof<FormValues>('goals'), 'Необходимо выбрать минимум одну цель', () => {
    enforce(data.goals).isNotEmpty();
  });

  test(nameof<FormValues>('constraints'), 'Должно быть задано хотя бы одно ограничение для проекта', () => {
    enforce(data.constraints.current).isNotEmpty();
  });

  test(
    nameof<FormValues>('constraints'),
    'Для выбранной стратегии должно быть задано ограничение по цене конверсии',
    () => {
      if (data.optimizationStrategy !== 'MAXIMUM_CONVERSION_VALUE') {
        return true;
      }

      const { constraints } = data;

      return (
        hasCPAConstraint(constraints.current) && (constraints.next.length === 0 || hasCPAConstraint(constraints.next))
      );
    }
  );

  each(data.constraints.current, (constraint, index) => {
    test(
      `constraints.current.${index}.max`,
      'Значение должно быть положительным числом',
      () => {
        enforce(constraint.max).greaterThan(0);
      },
      constraint.label
    );
  });

  each(data.constraints.next, (constraint, index) => {
    test(
      `constraints.next.${index}.max`,
      'Значение должно быть положительным числом',
      () => {
        enforce(constraint.max).greaterThan(0);
      },
      constraint.label
    );
  });

  test(
    nameof<FormValues>('avgOrderValue'),
    `Необходимо задать средний чек, так как используется ограничение «${translateConstraintLabel('CRR')}»`,
    () => {
      if (data.avgOrderValue > 0) {
        return true;
      }

      const { constraints } = data;

      return !hasCRRConstraint(constraints.current) && !hasCRRConstraint(constraints.next);
    }
  );

  test('_root', () => {
    if (data.avgOrderValue > 0) {
      return true;
    }

    for (const group of data.groups) {
      if (group.avgOrderValue > 0) {
        continue;
      }

      if (hasCRRConstraint(group.constraints.current) || hasCRRConstraint(group.constraints.next)) {
        return Promise.reject(
          `Необходимо задать средний чек на уровне проекта или группы «${
            group.name
          }», так как используется ограничение «${translateConstraintLabel('CRR')}»`
        );
      }
    }
  });

  test('_root', () => {
    if (data.autoImportCampaigns) {
      return true;
    }

    if (data.campaigns.length === 0) {
      return Promise.reject('Необходимо выбрать минимум одну рекламную кампанию');
    }
  });
});
