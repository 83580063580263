import {
  makeStyles,
  SelectTabEventHandler,
  shorthands,
  Tab,
  TabList,
  tokens,
  typographyStyles,
} from '@fluentui/react-components';
import { useCallback, useState } from 'react';
import { Forecast } from '../../../app/services/optimizer';
import { literally } from '../../../utils/literally';
import CampaignsPanel from './CampaignsPanel';
import Info from './Info';
import { SettingsPanel } from './SettingsPanel';
import StatsPanel from './StatsPanel';

export type TaskDetailsProps = {
  projectId: string;
  task: Forecast;
};

type TabValue = 'summary' | 'campaigns' | 'settings';

const useStyles = makeStyles({
  header: {
    ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalM),
    ...typographyStyles.title1,
  },
  tabList: {
    marginTop: tokens.spacingVerticalXL,
  },
  taskInfo: {
    marginLeft: tokens.spacingHorizontalM,
    marginRight: tokens.spacingHorizontalM,
  },
  panel: {
    ...shorthands.margin(tokens.spacingVerticalL, tokens.spacingHorizontalM),
  },
});

export const TaskDetails = ({ task, projectId }: TaskDetailsProps) => {
  const [selectedTab, setSelectedTab] = useState<TabValue>('summary');
  const handleTabSelect = useCallback<SelectTabEventHandler>(
    (ev, { value }) => {
      setSelectedTab(value as TabValue);
    },
    [setSelectedTab]
  );

  const classes = useStyles();

  return (
    <>
      <h1 className={classes.header}>Задача №{task.id}</h1>
      <Info className={classes.taskInfo} task={task} />
      <TabList className={classes.tabList} selectedValue={selectedTab} onTabSelect={handleTabSelect}>
        <Tab id="summary" value={literally<TabValue>('summary')}>
          Статистика
        </Tab>
        <Tab id="campaigns" value={literally<TabValue>('campaigns')}>
          Рекламные кампании
        </Tab>
        <Tab id="settings" value={literally<TabValue>('settings')}>
          Параметры
        </Tab>
      </TabList>
      {selectedTab === 'summary' && <StatsPanel className={classes.panel} task={task} />}
      {selectedTab === 'campaigns' && <CampaignsPanel className={classes.panel} projectId={projectId} task={task} />}
      {selectedTab === 'settings' && <SettingsPanel className={classes.panel} projectId={projectId} task={task} />}
    </>
  );
};
