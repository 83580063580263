import { makeStyles, mergeClasses, tokens, Tooltip } from '@fluentui/react-components';
import { SyncCounter } from '../../app/services/optimizer';

export type CounterStatProps = {
  className?: string;
  total: number;
  errors: number;
  errorDescription: string;
};

export type SyncStatProps = {
  counter: SyncCounter;
  className?: string;
};

const useStyles = makeStyles({
  root: {
    '& .warning': {
      color: tokens.colorPaletteDarkOrangeForeground3,
    },
  },
});

export const CounterStat = ({ total, errors, errorDescription, className }: CounterStatProps) => {
  const classes = useStyles();

  return (
    <div className={mergeClasses(classes.root, className)}>
      {total}
      {errors > 0 ? (
        <>
          {' '}
          <Tooltip relationship="description" content={errorDescription}>
            <span className="warning">({errors})</span>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export const SyncStat = ({ counter, className }: SyncStatProps) => (
  <CounterStat
    className={className}
    total={counter.total}
    errors={counter.errors}
    errorDescription="Количество ошибок при выгрузке данных в Яндекс.Директ"
  />
);
