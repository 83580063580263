import { tokens } from '@fluentui/react-components';
import {
  CheckmarkCircle20Filled,
  DismissCircle20Filled,
  ErrorCircle20Filled,
  SpinnerIos20Filled,
  Timer20Filled,
} from '@fluentui/react-icons';
import { FunctionComponent, SVGAttributes } from 'react';
import { ForecastStatus } from '../../app/services/optimizer';

const iconMap: Record<ForecastStatus, FunctionComponent<SVGAttributes<SVGElement>>> = {
  PENDING: Timer20Filled,
  RUNNING: SpinnerIos20Filled,
  SUCCESS: CheckmarkCircle20Filled,
  ERROR: ErrorCircle20Filled,
  CANCELLED: DismissCircle20Filled,
};

const colorMap: Record<ForecastStatus, string> = {
  PENDING: tokens.colorPaletteMarigoldForeground3,
  RUNNING: tokens.colorNeutralForeground2,
  SUCCESS: tokens.colorPaletteLightGreenForeground3,
  ERROR: tokens.colorPaletteRedForeground3,
  CANCELLED: tokens.colorNeutralForeground3,
};

export type StatusIconProps = {
  status: ForecastStatus;
};

export const StatusIcon = ({ status }: StatusIconProps) => {
  const IconElement = iconMap[status];

  return <IconElement color={colorMap[status]} />;
};
