import { Button, makeStyles, tokens } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { isApiError, isFetchError } from '../app/utils/error';
import { Error, NoAccess, NotFound } from './error';

export type FetchErrorProps = {
  error: any;
  className?: string;
  appearance?: 'default' | 'subtle';
  onRetry?: () => void;
};

function getMessage(error: any): string {
  if (isFetchError(error)) {
    if (error.status >= 500) {
      return 'Сервер временно недоступен. Пожалуйста, попробуйте повторить запрос позже.';
    }

    if (error.status === 403) {
      return 'Нет доступа к ресурсу';
    }

    if (error.status === 404) {
      return 'Ресурс не найден или был удален';
    }

    if (isApiError(error) && error.status > 0) {
      return `Сервер вернул ответ с кодом (${error.status}). Пожалуйста, попробуйте повторить запрос позже. Свяжитесь с поддержкой сервиса, если ошибка повторится.`;
    }
  }

  return 'Не удалось получить данные с сервера.';
}

function canRetry(error: any): boolean {
  return isApiError(error) && error.status >= 500;
}

const useStyles = makeStyles({
  retryButton: {
    marginTop: tokens.spacingVerticalL,
  },
});

export const FetchError = ({ error, className, onRetry, appearance = 'default' }: FetchErrorProps) => {
  const classes = useStyles();

  if (appearance === 'default') {
    if (isApiError(error)) {
      if (error.status === 403) {
        return <NoAccess className={className} />;
      }

      if (error.status === 404) {
        return <NotFound className={className} />;
      }
    }

    return (
      <Error className={className}>
        <h1>Ошибка</h1>
        <div>{getMessage(error)}</div>
        {canRetry(error) && onRetry && (
          <Button className={classes.retryButton} appearance="secondary" onClick={onRetry}>
            Повторить
          </Button>
        )}
      </Error>
    );
  }

  return (
    <Alert
      className={className}
      intent="error"
      action={canRetry(error) && onRetry ? { children: 'Повторить', onClick: onRetry } : undefined}
    >
      {getMessage(error)}
    </Alert>
  );
};

export default FetchError;
