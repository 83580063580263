import { useEffect } from 'react';

export function useDocumentTitle(titles: Promise<string[]>, separator: string = ' / ') {
  useEffect(() => {
    let mounted = true;

    titles.then((items) => {
      if (!mounted) {
        return;
      }

      document.title = items.join(separator);
    });

    return () => {
      mounted = false;
    };
  }, [titles, separator]);
}
