import { Button, makeStyles, mergeClasses } from '@fluentui/react-components';
import { useNavigate } from 'react-router';
import searchImg from '../../assets/search.svg';
import Error from './Error';

export type NotFoundProps = {
  className?: string;
};

const useStyles = makeStyles({
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px',
    backgroundPositionX: '80%',
    backgroundPositionY: '80%',
    backgroundImage: `url(${searchImg})`,
  },
});

export const NotFound = ({ className }: NotFoundProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Error className={mergeClasses(classes.image, className)}>
      <h1>Страница не найдена</h1>
      <p>Проверьте, нет ли опечатки в адресе.</p>
      <p>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </p>
    </Error>
  );
};
