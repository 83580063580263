import { Label, makeStyles, tokens, typographyStyles } from '@fluentui/react-components';
import { FormattedNumber } from 'react-intl';
import { Forecast, translateAttributionModel, translateOptimizationStrategy } from '../../../../app/services/optimizer';
import ConstraintView from '../../../../components/ConstraintView';
import { currencyOptions } from '../../../../utils/intl';
import GoalsList from '../GoalsList';
import GroupsList from './GroupsList';

export type SettingsPanelProps = {
  className?: string;
  projectId: string;
  task: Forecast;
};

const useStyles = makeStyles({
  field: {
    marginBottom: tokens.spacingVerticalM,
  },
  label: {
    display: 'block',
    ...typographyStyles.subtitle2,
  },
  goalsList: {
    maxWidth: '760px',
  },
});

export const SettingsPanel = ({ className, projectId, task }: SettingsPanelProps) => {
  const classes = useStyles();

  return (
    <div className={className} role="tabpanel" aria-labelledby="Параметры задачи">
      <div className={classes.field}>
        <Label className={classes.label}>Стратегия оптимизации</Label>
        {translateOptimizationStrategy(task.optimizationStrategy)}
      </div>
      <div className={classes.field}>
        <Label className={classes.label}>Модель атрибуции</Label>
        {translateAttributionModel(task.attributionModel)}
      </div>
      <div className={classes.field}>
        <Label className={classes.label}>Цели</Label>
        <GoalsList className={classes.goalsList} projectId={projectId} goals={task.goals} />
      </div>
      <div className={classes.field}>
        <Label className={classes.label}>Средний чек</Label>
        {task.avgOrderValue > 0 ? <FormattedNumber value={task.avgOrderValue} {...currencyOptions} /> : '—'}
      </div>
      <div className={classes.field}>
        <Label className={classes.label}>НДС</Label>
        {task.includeVAT ? 'с учётом НДС' : 'без НДС'}
      </div>
      {task.monthlyBudget !== undefined && (
        <div className={classes.field}>
          <Label className={classes.label}>Период учета расхода</Label>
          {task.monthlyBudget ? 'календарный месяц' : '28 дней'}
        </div>
      )}
      <div className={classes.field}>
        <Label className={classes.label}>Общие ограничения</Label>
        {task.constraints.map((item, index) => (
          <ConstraintView key={index} constraint={item} />
        ))}
      </div>
      <div className={classes.field}>
        <Label className={classes.label}>Группы</Label>
        <GroupsList groups={task.groups} />
      </div>
    </div>
  );
};
