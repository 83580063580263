import {
  Button,
  Field,
  FieldProps,
  Label,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellActions,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  tokens,
} from '@fluentui/react-components';
import {
  AddSquareFilled,
  AddSquareRegular,
  bundleIcon,
  DeleteFilled,
  DeleteRegular,
  EditFilled,
  EditRegular,
} from '@fluentui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { FieldPathByValue, useController, UseControllerProps } from 'react-hook-form';
import { Goal, GoalItem } from '../../../app/services/optimizer';
import { enhanceFieldProps } from '../../../components/form/utils';
import { FormValues } from '../form';
import AddGoalDialog, { AddGoalEventHandler } from './AddGoalDialog';
import EditGoalDialog from './EditGoalDialog';
import { SelectedGoal } from './SelectedGoal';

const AddIcon = bundleIcon(AddSquareFilled, AddSquareRegular);
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export type GoalsFieldProps = Omit<FieldProps, 'label'> &
  UseControllerProps<FormValues, FieldPathByValue<FormValues, GoalItem[]>> & {
    label?: string;
    options: Goal[];
  };

type State = {
  addDialogOpen: boolean;
  editGoal: SelectedGoal | undefined;
};

const defaultState: State = {
  addDialogOpen: false,
  editGoal: undefined,
};

const useStyles = makeStyles({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  empty: {
    textAlign: 'center',
    color: tokens.colorNeutralForegroundDisabled,
  },
  cellId: {
    width: '100px',
  },
  cellWeight: {
    width: '100px',
  },
});

export const GoalsField = ({ options, label = 'Цели', ...rest }: GoalsFieldProps) => {
  const {
    field: { value, onChange },
    fieldState,
  } = useController(rest);
  const [state, setState] = useState<State>(defaultState);
  const selectedGoals = useMemo<SelectedGoal[]>(() => {
    if (!value) {
      return [];
    }

    return value.map((item) => {
      return new SelectedGoal(
        item,
        options.find((goal) => goal.id === item.id)
      );
    });
  }, [value, options]);
  const avaliableOptions = useMemo<Goal[]>(() => {
    const selectedIds = value.map((item) => item.id);

    return options.filter((goal) => !selectedIds.includes(goal.id));
  }, [options, value]);
  const editItem = useCallback(
    (goal: SelectedGoal) => {
      setState((prevState) => ({
        ...prevState,
        editGoal: goal,
      }));
    },
    [setState]
  );
  const removeItem = useCallback(
    (goalId: number) => {
      onChange(value.filter((item) => item.id !== goalId));
    },
    [value, onChange]
  );
  const handleAddGoalClick = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      addDialogOpen: true,
    }));
  }, [setState]);
  const closeAddGoalDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      addDialogOpen: false,
    }));
  }, [setState]);
  const closeEdigGoalDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      editGoal: undefined,
    }));
  }, [setState]);
  const handleGoalAdd = useCallback<AddGoalEventHandler>(
    (goalItem) => {
      onChange([...value, goalItem].sort((a, b) => a.id - b.id));
      closeAddGoalDialog();
    },
    [onChange, value, closeAddGoalDialog]
  );
  const handleGoalChange = useCallback(
    (goalItem: GoalItem) => {
      onChange(
        value.map((item) => {
          if (item.id === goalItem.id) {
            return goalItem;
          }

          return item;
        })
      );
      closeEdigGoalDialog();
    },
    [value, onChange, closeEdigGoalDialog]
  );
  const classes = useStyles();

  return (
    <>
      <Field {...enhanceFieldProps(rest, fieldState)}>
        <>
          <div className={classes.labelWrapper}>
            <Label>{label}</Label>
            <Button
              appearance="transparent"
              icon={<AddIcon />}
              onClick={handleAddGoalClick}
              disabled={avaliableOptions.length === 0}
            >
              Добавить
            </Button>
          </div>
          {selectedGoals.length === 0 && (
            <div className={classes.empty}>
              <div>Не выбрано ни одной цели</div>
              {avaliableOptions.length > 0 && (
                <Button appearance="transparent" icon={<AddIcon />} size="small" onClick={handleAddGoalClick}>
                  Добавить
                </Button>
              )}
            </div>
          )}
          {selectedGoals.length > 0 && (
            <Table size="small">
              <TableHeader>
                <TableRow>
                  <TableHeaderCell className={classes.cellId}>ID</TableHeaderCell>
                  <TableHeaderCell>Название</TableHeaderCell>
                  <TableHeaderCell className={classes.cellWeight}>Вес</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {selectedGoals.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <TableCellLayout>{item.weight}</TableCellLayout>
                      <TableCellActions>
                        <Button
                          aria-label="Редактировать"
                          icon={<EditIcon />}
                          appearance="subtle"
                          size="small"
                          onClick={() => {
                            editItem(item);
                          }}
                        />
                        <Button
                          aria-label="Удалить"
                          icon={<DeleteIcon />}
                          appearance="subtle"
                          size="small"
                          onClick={() => {
                            removeItem(item.id);
                          }}
                        />
                      </TableCellActions>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </>
      </Field>
      {state.addDialogOpen && (
        <AddGoalDialog goals={avaliableOptions} onAdd={handleGoalAdd} onDismiss={closeAddGoalDialog} />
      )}
      {state.editGoal && (
        <EditGoalDialog goal={state.editGoal} onChange={handleGoalChange} onDismiss={closeEdigGoalDialog} />
      )}
    </>
  );
};

export default GoalsField;
