import {
  Input,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  Spinner,
  tokens,
  typographyStyles,
} from '@fluentui/react-components';
import { Search20Regular } from '@fluentui/react-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectItem, useGetProjectsQuery } from '../../app/services/project';

type MenuItemState = {
  project: ProjectItem;
  handleClick?: () => void;
};

export type SelectProjectEventHandler = (project: ProjectItem) => void;

export type ProjectPickerProps = {
  selectedProjectId?: string;
  selectFirstByDefault?: boolean;
  onSelect: SelectProjectEventHandler;
};

const useStyles = makeStyles({
  title: {
    color: tokens.colorBrandBackgroundInvertedSelected,
    ...typographyStyles.subtitle1,
  },
  menuPopover: {
    maxWidth: '600px',
  },
  search: {
    width: '100%',
  },
});

function getProjectName(projects: ProjectItem[], projectId: string | undefined): string | undefined {
  if (!projectId) {
    return undefined;
  }

  const project = projects.find((project) => project.id === projectId);

  if (project) {
    return project.name;
  }

  return undefined;
}

export const ProjectPicker = ({ selectedProjectId, onSelect, selectFirstByDefault = false }: ProjectPickerProps) => {
  const { data: projects, isLoading } = useGetProjectsQuery();
  const menuItemsState = useMemo<MenuItemState[]>(() => {
    if (!projects) {
      return [];
    }

    return projects.map((project) => ({
      project,
      handleClick: project.id !== selectedProjectId ? () => onSelect(project) : undefined,
    }));
  }, [projects, selectedProjectId, onSelect]);
  const [query, setQuery] = useState<string>('');
  const handleOpenMenuChange = useCallback<NonNullable<MenuProps['onOpenChange']>>(
    (ev, { open }) => {
      if (open === false) {
        setQuery('');
      }
    },
    [setQuery]
  );
  const classes = useStyles();

  useEffect(() => {
    if (selectFirstByDefault && !selectedProjectId && projects && projects.length > 0) {
      onSelect(projects[0]);
    }
  }, [projects, selectedProjectId, selectFirstByDefault, onSelect]);

  if (isLoading) {
    return <Spinner />;
  }

  const filteredItems = query
    ? menuItemsState.filter(({ project }) => project.name.toLowerCase().indexOf(query.toLowerCase()) > -1)
    : menuItemsState;

  return (
    <Menu onOpenChange={handleOpenMenuChange}>
      <MenuTrigger>
        <MenuButton className={classes.title} appearance="subtle">
          {getProjectName(projects || [], selectedProjectId) || 'Проект не выбран'}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover className={classes.menuPopover}>
        <MenuList>
          <Input
            className={classes.search}
            appearance="underline"
            onChange={(e, { value }) => {
              setQuery(value);
            }}
            contentAfter={<Search20Regular />}
          />
          {filteredItems.map(({ project, handleClick }) => (
            <MenuItem key={project.id} onClick={handleClick}>
              {project.name}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default ProjectPicker;
