import { Button, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { CaretLeft24Filled, CaretRight24Filled, Next24Regular, Previous24Regular } from '@fluentui/react-icons';
import { useCallback, useMemo } from 'react';

export type PaginationProps = {
  className?: string;
  itemsPerPage: number;
  totalItemsCount: number;
  selectedPageIndex?: number;
  numberOfPageButton?: number;
  onPageChange: (index: number) => void;
};

type PageNumber = {
  index: number;
  onClick: () => void;
};

function renderVisibleItemLabel(selectedPageIndex: number, itemsPerPage: number, totalItemsCount: number): string {
  const leftItemIndex = selectedPageIndex! * itemsPerPage + 1;
  const rightItemsIndex = Math.min((selectedPageIndex + 1) * itemsPerPage, totalItemsCount);

  return `${leftItemIndex} – ${rightItemsIndex} из ${totalItemsCount}`;
}

function getPageList(pageCount: number, numberOfPageButton: number, selectedPageIndex: number): number[] {
  if (pageCount <= numberOfPageButton) {
    return Array.from(Array(pageCount).keys());
  }

  const halfCount = Math.floor((numberOfPageButton - 1) / 2);
  let offset = selectedPageIndex - halfCount;

  if (selectedPageIndex + halfCount > pageCount - 1) {
    offset = pageCount - numberOfPageButton;
  }
  if (offset < 0) {
    offset = 0;
  }

  return Array.from({ length: numberOfPageButton }, (_, i) => i + offset);
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pageNumber: {
    minWidth: '32px',
  },
  label: {
    color: tokens.colorNeutralForeground3,
  },
});

export const Pagination = ({
  className,
  totalItemsCount,
  itemsPerPage,
  onPageChange,
  selectedPageIndex = 0,
  numberOfPageButton = 5,
}: PaginationProps) => {
  const pageCount = Math.ceil(totalItemsCount / itemsPerPage);
  const canPrevious = selectedPageIndex > 0;
  const canNext = selectedPageIndex + 1 < pageCount;
  const canFirst = selectedPageIndex !== 0;
  const canLast = selectedPageIndex !== pageCount - 1;

  const handleFirstPageClick = useCallback(() => {
    onPageChange(0);
  }, [onPageChange]);
  const handleLastPageClick = useCallback(() => {
    onPageChange(pageCount - 1);
  }, [pageCount, onPageChange]);
  const handlePreviousePageClick = useCallback(() => {
    onPageChange(selectedPageIndex - 1);
  }, [selectedPageIndex, onPageChange]);
  const handleNextPageClick = useCallback(() => {
    onPageChange(selectedPageIndex + 1);
  }, [selectedPageIndex, onPageChange]);

  const pages = useMemo<PageNumber[]>(
    () =>
      getPageList(pageCount, numberOfPageButton, selectedPageIndex).map((index) => ({
        index,
        onClick: () => {
          onPageChange(index);
        },
      })),
    [pageCount, numberOfPageButton, selectedPageIndex, onPageChange]
  );

  const classes = useStyles();

  return (
    <div className={mergeClasses(classes.root, className)}>
      <div>
        <Button
          icon={<Previous24Regular />}
          appearance="transparent"
          disabled={!canFirst}
          aria-label="Первая страница"
          onClick={handleFirstPageClick}
        />
        <Button
          icon={<CaretLeft24Filled />}
          appearance="transparent"
          disabled={!canPrevious}
          aria-label="Предыдущая страница"
          onClick={handlePreviousePageClick}
        />
        {pages.map(({ index, onClick }) => (
          <Button
            key={index}
            className={classes.pageNumber}
            appearance="subtle"
            disabled={selectedPageIndex === index}
            aria-label={`Страница ${index + 1} из ${pageCount}`}
            aria-selected={selectedPageIndex === index}
            onClick={onClick}
          >{`${index + 1}`}</Button>
        ))}
        <Button
          icon={<CaretRight24Filled />}
          appearance="transparent"
          disabled={!canNext}
          aria-label="Следующая страница"
          onClick={handleNextPageClick}
        />
        <Button
          icon={<Next24Regular />}
          appearance="transparent"
          disabled={!canLast}
          aria-label="Последняя страница"
          onClick={handleLastPageClick}
        />
      </div>
      <div className={classes.label}>
        {renderVisibleItemLabel(selectedPageIndex || 0, itemsPerPage, totalItemsCount)}
      </div>
    </div>
  );
};

export default Pagination;
