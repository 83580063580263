import { makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Constraint, translateConstraintLabel } from '../app/services/optimizer';
import { currencyOptions } from '../utils/intl';

type ConstraintLabel = Constraint['label'];

export type ConstraintProps = {
  constraint: Constraint;
};

const getFormatOptions = (label: ConstraintLabel) => {
  if (label === 'Cost' || label === 'CPA') {
    return currencyOptions;
  }

  return {};
};

const useStyles = makeStyles({
  root: {
    '& strong': {
      color: tokens.colorNeutralForeground2,
      fontWeight: tokens.fontWeightSemibold,
    },
  },
});

export const ConstraintView = ({ constraint }: ConstraintProps) => {
  const classes = useStyles();

  const cond = [];

  if (constraint.min > 0) {
    cond.push(
      <React.Fragment key="lower-bound">
        не менее чем{' '}
        <strong>
          <FormattedNumber value={constraint.min} {...getFormatOptions(constraint.label)} />
        </strong>
      </React.Fragment>
    );
  }

  if (constraint.max > 0) {
    if (cond.length) {
      cond.push(' И ');
    }

    cond.push(
      <React.Fragment key="upper-bound">
        не более чем{' '}
        <strong>
          <FormattedNumber value={constraint.max} {...getFormatOptions(constraint.label)} />
        </strong>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <strong>{translateConstraintLabel(constraint.label)}</strong> {cond}
    </div>
  );
};

export default ConstraintView;
