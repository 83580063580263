import {
  Button,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tokens,
} from '@fluentui/react-components';
import { Add24Filled, Add24Regular, bundleIcon, Delete24Filled, Delete24Regular } from '@fluentui/react-icons';
import { useMemo } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { ConstraintLabel, translateConstraintLabel } from '../../../app/services/optimizer';
import { InputField } from '../../../components/form';
import { getSelectableConstraintLabels } from '../data';
import { FormValues } from '../form';

const AddIcon = bundleIcon(Add24Filled, Add24Regular);
const DeleteIcon = bundleIcon(Delete24Filled, Delete24Regular);

export type ConstraintsListProps = {
  control: Control<FormValues>;
  label: string;
  name: 'constraints.current' | 'constraints.next';
  emptyMessage: string;
};

const useStyles = makeStyles({
  header: {
    display: 'flex',
  },
  empty: {
    textAlign: 'center',
    color: tokens.colorNeutralForegroundDisabled,
  },
  cellName: {
    width: '30%',
  },
  cellActions: {
    width: '30px',
    textAlign: 'right',
  },
  field: {
    '& .fui-Input': {
      width: '100px',
    },
    '& .fui-Field__label': {
      textAlign: 'right',
    },
  },
});

type ConstraintFieldValues = {
  id: string;
  label: ConstraintLabel;
  min: number;
  max: number;
};

export const ConstraintsList = ({ label, control, name, emptyMessage }: ConstraintsListProps) => {
  const { fields, append, remove } = useFieldArray<FormValues>({
    control,
    name,
  });
  const availableConstraints = useMemo(
    () => getSelectableConstraintLabels((fields as ConstraintFieldValues[]).map((field) => field.label)),
    [fields]
  );

  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <Divider>{label}</Divider>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button
              aria-label="Добавить"
              appearance="subtle"
              icon={<AddIcon />}
              disabled={availableConstraints.length === 0}
            />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {availableConstraints.map((label) => (
                <MenuItem
                  key={label}
                  onClick={() => {
                    append({ label, min: 0, max: 0 });
                  }}
                >
                  {translateConstraintLabel(label)}
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
      {fields.length === 0 && <div className={classes.empty}>{emptyMessage}</div>}
      {fields.length > 0 && (
        <Table size="extra-small">
          <TableBody>
            {(fields as ConstraintFieldValues[]).map((item, index) => (
              <TableRow key={item.id}>
                <TableCell className={classes.cellName}>{translateConstraintLabel(item.label)}</TableCell>
                <TableCell>
                  <InputField
                    className={classes.field}
                    control={control as any}
                    type="number"
                    name={`${name}.${index}.max`}
                    min={0}
                    label="не более чем"
                    orientation="horizontal"
                    size="small"
                  />
                </TableCell>
                <TableCell className={classes.cellActions}>
                  <Button
                    aria-label="Удалить"
                    appearance="subtle"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ConstraintsList;
