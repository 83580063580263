import {
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  mergeClasses,
  TableCellActions,
  TableCellLayout,
  TableColumnDefinition,
} from '@fluentui/react-components';
import { bundleIcon, ContentViewFilled, ContentViewRegular } from '@fluentui/react-icons';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { ForecastItem } from '../../app/services/optimizer';
import { CounterStat, SyncStat } from './CounterStat';
import { translateTaskStatus } from './data';
import { StatusIcon } from './StatusIcon';

const ViewIcon = bundleIcon(ContentViewFilled, ContentViewRegular);

export type TaskListProps = {
  className?: string;
  items: ForecastItem[];
};

type ListItem = {
  task: ForecastItem;
  forecastDate: string;
  goToDetails: () => void;
};

const columns: TableColumnDefinition<ListItem>[] = [
  createTableColumn({
    columnId: 'id',
    renderHeaderCell: () => '№',
    renderCell: ({ task }) => task.id,
  }),
  createTableColumn({
    columnId: 'forecastDate',
    renderHeaderCell: () => 'Дата прогноза',
    renderCell: (item) => item.forecastDate,
  }),
  createTableColumn({
    columnId: 'status',
    renderHeaderCell: () => 'Статус',
    renderCell: ({ task, goToDetails }) => (
      <>
        <TableCellLayout media={<StatusIcon status={task.status} />}>
          {translateTaskStatus(task.status)}
        </TableCellLayout>
        <TableCellActions>
          <Button icon={<ViewIcon />} appearance="subtle" onClick={goToDetails} />
        </TableCellActions>
      </>
    ),
  }),
  createTableColumn({
    columnId: 'campaignCounts',
    renderHeaderCell: () => 'Кампании',
    renderCell: ({ task }) => (
      <CounterStat
        total={task.campaignCounts.total}
        errors={task.campaignCounts.rejected}
        errorDescription="Количество отклоненных рекламных кампаний"
      />
    ),
  }),
  createTableColumn({
    columnId: 'bidCounts',
    renderHeaderCell: () => 'Ставки',
    renderCell: ({ task }) => <SyncStat counter={task.bidCounts} />,
  }),
  createTableColumn({
    columnId: 'bidModifierCounts',
    renderHeaderCell: () => 'Корректировки',
    renderCell: ({ task }) => <SyncStat counter={task.bidModifierCounts} />,
  }),
];

const getRowId = (item: ListItem) => item.task.id;

const useStyles = makeStyles({
  cellId: {
    width: '100px',
    maxWidth: '100px',
  },
  cellForecastDate: {
    width: '100px',
    maxWidth: '100px',
  },
  cellStatus: {
    width: '120px',
    minWidth: '120px',
  },
});

export const TaskList = ({ className, items }: TaskListProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const listItems = useMemo<ListItem[]>(
    () =>
      items.map((task) => ({
        task,
        forecastDate: intl.formatDate(task.forecastDate),
        goToDetails: () => {
          navigate(`${task.id}`);
        },
      })),
    [items, intl, navigate]
  );

  const classes = useStyles();

  return (
    <DataGrid className={className} columns={columns} items={listItems} getRowId={getRowId} selectionAppearance="none">
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell, columnId }) => (
            <DataGridHeaderCell
              className={mergeClasses(
                columnId === 'id' && classes.cellId,
                columnId === 'forecastDate' && classes.cellForecastDate,
                columnId === 'status' && classes.cellStatus
              )}
            >
              {renderHeaderCell()}
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<ListItem>>
        {({ item, rowId }) => (
          <DataGridRow key={rowId} onDoubleClick={item.goToDetails}>
            {({ renderCell, columnId }) => (
              <DataGridCell
                className={mergeClasses(
                  columnId === 'id' && classes.cellId,
                  columnId === 'forecastDate' && classes.cellForecastDate,
                  columnId === 'status' && classes.cellStatus
                )}
              >
                {renderCell(item)}
              </DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};

export default TaskList;
