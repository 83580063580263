import { Field, FieldProps, Option } from '@fluentui/react-components';
import { ReactNode, useCallback } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { Dropdown, DropdownOptionSelectEventHandler, DropdownProps } from '../Combobox';
import { Transform, TransformProps } from './types';
import { enhanceFieldProps, getInputValue, getTransformOutputFunction, mergeCallbacks } from './utils';

export type DropdownOption<TValue extends string = string> = {
  text: string;
  value: TValue;
};

export type OptionsRenderFunction<TOption extends DropdownOption = DropdownOption> = (option: TOption[]) => ReactNode[];

export type DropdownFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TOption extends DropdownOption = DropdownOption
> = Omit<FieldProps, 'children' | 'validationMessage' | 'validationState'> &
  Omit<DropdownProps, 'children' | 'selectedOptions' | 'value'> &
  UseControllerProps<TFieldValues> &
  TransformProps & {
    children?: OptionsRenderFunction<TOption>;
    options: TOption[];
  };

const singleValueTransform: Transform = {
  input: (value?: string) => (value ? [value] : []),
  output: (value: string[]) => {
    if (value.length === 0) {
      return undefined;
    }

    return value[0];
  },
};

export const DropdownField = <
  TFieldValues extends FieldValues = FieldValues,
  TOption extends DropdownOption = DropdownOption
>({
  className,
  onOptionSelect,
  onBlur,
  label,
  hint,
  transform,
  children,
  options,
  multiselect = false,
  ...rest
}: DropdownFieldProps<TFieldValues, TOption>) => {
  const { field, fieldState } = useController(rest);

  let finalTransformer = transform;

  if (!multiselect && !finalTransformer) {
    finalTransformer = singleValueTransform;
  }

  const transformOutput = getTransformOutputFunction(finalTransformer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBlur = useCallback(mergeCallbacks(onBlur, field.onBlur), [onBlur, field.onBlur]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOptionSelect = useCallback<DropdownOptionSelectEventHandler>(
    mergeCallbacks(onOptionSelect, (ev, data) => {
      field.onChange(transformOutput(data.selectedOptions));
    }),
    [onOptionSelect, field.onChange, transformOutput]
  );

  const renderOptions =
    typeof children === 'function'
      ? children
      : (options: TOption[]) =>
          options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ));

  const selectedOptions = getInputValue(field, finalTransformer);
  const value = selectedOptions
    ? (selectedOptions as string[])
        .map((value) => options.find((option) => option.value === value)?.text || value)
        .join(', ')
    : undefined;

  return (
    <Field className={className} label={label} hint={hint} {...enhanceFieldProps(rest, fieldState)}>
      <Dropdown
        ref={field.ref}
        multiselect={multiselect}
        selectedOptions={selectedOptions}
        value={value}
        onBlur={handleBlur}
        onOptionSelect={handleOptionSelect}
        {...rest}
      >
        {renderOptions(options)}
      </Dropdown>
    </Field>
  );
};
