import { makeStyles, mergeClasses, tokens, Tooltip } from '@fluentui/react-components';
import { FormatNumberOptions, FormattedNumber } from 'react-intl';

export type MetricValueProps = {
  forecast: number;
  fact: number;
  formatOptions?: FormatNumberOptions;
};

const metricValueClasses = {
  root: 'metric-value',
};

const useStyle = makeStyles({
  root: {
    '& .metric-value__forecast': {
      fontWeight: tokens.fontWeightSemibold,
    },
    '& .metric-value__fact': {
      color: tokens.colorNeutralForeground4,
    },
  },
});

export const MetricValue = ({ forecast, fact, formatOptions }: MetricValueProps) => {
  const classes = useStyle();

  return (
    <div className={mergeClasses(metricValueClasses.root, classes.root)}>
      <Tooltip relationship="description" content="Прогнозное значение">
        <div className="metric-value__forecast">
          <FormattedNumber value={forecast} {...(formatOptions || {})} />
        </div>
      </Tooltip>
      <Tooltip relationship="description" content="Значение за предыдущие 28 дней">
        <div className="metric-value__fact">
          <FormattedNumber value={fact} {...(formatOptions || {})} />
        </div>
      </Tooltip>
    </div>
  );
};

export default MetricValue;
