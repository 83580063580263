import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { FormattedDate } from 'react-intl';
import { Forecast } from '../../../app/services/optimizer';
import { translateForecastErrorCode, translateTaskStatus } from '../data';

export type InfoProps = {
  className?: string;
  task: Forecast;
};

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'start',
    ...shorthands.gap(tokens.spacingHorizontalMNudge, tokens.spacingVerticalXS),
  },
  label: {
    color: tokens.colorNeutralForeground4,
  },
});

export const Info = ({ className, task }: InfoProps) => {
  const classes = useStyles();

  return (
    <div className={mergeClasses(classes.root, className)}>
      <div className={classes.label}>Дата прогноза:</div>
      <div>
        <FormattedDate value={task.forecastDate} year="numeric" month="long" day="numeric" weekday="short" />
      </div>
      <div className={classes.label}>Статус задачи:</div>
      <div>
        <span>{translateTaskStatus(task.status)}</span>
      </div>
      {task.errorCode && (
        <>
          <div className={classes.label}>Причина:</div>
          <div>{translateForecastErrorCode(task.errorCode)}</div>
        </>
      )}
    </div>
  );
};

export default Info;
