import Rollbar from 'rollbar';
import { ENVIRONMENT, ROLLBAR_ACCESS_TOKEN, ROLLBAR_ENABLED, VERSION } from './config';

export const rollbar = new Rollbar({
  enabled: ROLLBAR_ENABLED,
  accessToken: ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: ENVIRONMENT,
    client: {
      javascript: {
        code_version: VERSION,
        source_map_enabled: true,
      },
    },
  },
});

export default rollbar;
