import { makeStyles, Option, tokens } from '@fluentui/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Goal } from '../../../app/services/optimizer';
import { Combobox, ComboboxChangeEventHandler, ComboboxOptionSelectEventHandler } from '../../../components/Combobox';

export type GoalSelectEventHandler = (goal?: Goal) => void;

export type GoalsDropdownProps = {
  goals: Goal[];
  onSelect: GoalSelectEventHandler;
  selectedGoal?: Goal['id'];
};

const useStyles = makeStyles({
  optionPrimary: {
    flexGrow: 1,
  },
  optionSecondary: {
    marginLeft: tokens.spacingHorizontalM,
    color: tokens.colorNeutralForeground3,
  },
});

export const GoalsDropdown = ({ goals, selectedGoal, onSelect }: GoalsDropdownProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (selectedGoal) {
      setValue(goals.find((goal) => goal.id === selectedGoal)?.name || '');
    } else {
      setValue('');
    }
  }, [goals, selectedGoal, setValue]);

  const matchingGoals = useMemo<Goal[]>(() => {
    if (!value) {
      return goals;
    }

    const query = value.toLowerCase();

    return goals.filter((goal) => goal.name.toLowerCase().indexOf(query) > -1);
  }, [goals, value]);

  const handleChange = useCallback<ComboboxChangeEventHandler>(
    ({ target }) => {
      setValue(target.value);
    },
    [setValue]
  );
  const handleOptionSelect = useCallback<ComboboxOptionSelectEventHandler>(
    (ev, { optionText, optionValue }) => {
      setValue(optionText || '');

      if (!optionValue) {
        onSelect();
        return;
      }

      const goalId = Number(optionValue);

      onSelect(goals.find((goal) => goal.id === goalId));
    },
    [goals, onSelect, setValue]
  );

  const classes = useStyles();

  return (
    <Combobox
      onOptionSelect={handleOptionSelect}
      onChange={handleChange}
      selectedOptions={selectedGoal ? [`${selectedGoal}`] : []}
      value={value}
      placeholder="Выберите цель"
      freeform
    >
      {matchingGoals.map((goal) => (
        <Option key={goal.id} value={`${goal.id}`} text={goal.name} data-testid={`option-goal-${goal.id}`}>
          <div className={classes.optionPrimary}>{goal.name}</div>
          <div className={classes.optionSecondary}>({goal.id})</div>
        </Option>
      ))}
      {matchingGoals.length === 0 ? (
        <Option key="no-results" text="" disabled>
          Нет результатов
        </Option>
      ) : null}
    </Combobox>
  );
};
