export const __DEV__ = process.env.REACT_APP_ENV !== 'production';

export const DEBUG = __DEV__ || process.env.REACT_APP_DEBUG === 'true';
export const ENVIRONMENT = process.env.REACT_APP_ENV || process.env.NODE_ENV;
export const VERSION = process.env.REACT_APP_REVISION || 'DEV';

export const SESSION_KEY = 'usrauth';

export const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_TOKEN;
export const ROLLBAR_ENABLED = !!ROLLBAR_ACCESS_TOKEN;

export const CBO_API_BASE_URL = 'https://api.cost-optimizer.airmediapro.ru/v2';
export const PROJECT_API_BASE_URl = 'https://api.projects.airmediapro.ru/api';
export const USER_API_BASE_URL = 'https://api.users.airmediapro.ru/api';
