import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const searchParamsDefaults = {
  page: '1',
};

export type SetPageNumber = (index: number) => void;

export function usePageNumber(): [number, SetPageNumber] {
  const [searchParams, setSearchParams] = useSearchParams(searchParamsDefaults);
  const setPageNumber = useCallback<SetPageNumber>(
    (index) => {
      setSearchParams((prev) => {
        prev.set('page', `${index + 1}`);

        return prev;
      });
    },
    [setSearchParams]
  );

  let pageNumber = 1;

  if (searchParams.has('page')) {
    pageNumber = Number(searchParams.get('page'));

    if (isNaN(pageNumber) || pageNumber < 1) {
      pageNumber = 1;
    }
  }

  return [pageNumber - 1, setPageNumber];
}
