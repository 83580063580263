import { Link, makeStyles, shorthands, typographyStyles } from '@fluentui/react-components';
import Logo from '../../components/Logo';
import SignInForm from './SignInForm';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    boxSizing: 'border-box',
    maxWidth: '340px',
    height: '100vh',
    ...shorthands.margin('0', 'auto'),
    ...shorthands.padding('25px'),
    ...shorthands.gap('25px'),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...shorthands.gap('15px'),
    '& h1': {
      ...typographyStyles.title3,
      ...shorthands.margin('0px'),
    },
  },
});

export const Login = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Logo size={48} />
        <h1>Оптимизатор</h1>
      </div>
      <div>
        <SignInForm />
      </div>
      <div>
        Нет учетной записи?{' '}
        <Link inline href="https://users.airmediapro.ru">
          Создайте её!
        </Link>
      </div>
    </div>
  );
};

export default Login;
