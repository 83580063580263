import { Field, FieldProps } from '@fluentui/react-components';
import { FieldPathByValue, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { enhanceFieldProps } from '../../../components/form/utils';
import { ConstraintsValues } from '../form';
import ConstraintsList from './ConstraintsList';

export type ConstraintsFieldProps<TFieldValues extends FieldValues = FieldValues> = FieldProps &
  UseControllerProps<TFieldValues, FieldPathByValue<TFieldValues, ConstraintsValues>>;

export const ConstraintsField = <TFieldValues extends FieldValues = FieldValues>(
  props: ConstraintsFieldProps<TFieldValues>
) => {
  const {
    field: { name },
    fieldState,
  } = useController(props);

  return (
    <Field {...enhanceFieldProps(props, fieldState)}>
      <>
        <ConstraintsList
          control={props.control as any}
          name={`${name}.current` as 'constraints.current'}
          label="Текущий месяц"
          emptyMessage="Нет ни одного ограничения"
        />
        <ConstraintsList
          control={props.control as any}
          name={`${name}.next` as 'constraints.next'}
          label="Следующий месяц"
          emptyMessage="Будут использованы ограничения предыдущего месяца"
        />
      </>
    </Field>
  );
};

export default ConstraintsField;
