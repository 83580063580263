import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { USER_API_BASE_URL } from '../../config';
import { loggedOut } from '../../features/auth/authSlice';
import { getAccessToken, getRefreshToken, storeSession } from '../session';
import { retry } from './retry';

export function signWithToken(headers: Headers): Headers {
  const token = getAccessToken();

  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }

  return headers;
}

type RefreshTokenResponse = {
  data: {
    access_token: string;
    refresh_token: string;
  };
};

const defaultAuthBaseQuery = retry(
  fetchBaseQuery({
    baseUrl: USER_API_BASE_URL,
  })
);

let refreshTokenPromise: Promise<boolean> | undefined;

async function refreshToken(baseQuery: BaseQueryFn, api: BaseQueryApi): Promise<boolean> {
  if (refreshTokenPromise) {
    return refreshTokenPromise;
  }

  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    return Promise.resolve(false);
  }

  refreshTokenPromise = Promise.resolve(
    baseQuery({ url: '/auth/token', method: 'POST', body: { data: { refresh_token: refreshToken } } }, api, {})
  )
    .then(({ data, error }) => {
      if (data) {
        const { access_token, refresh_token } = (data as RefreshTokenResponse).data;
        storeSession(access_token, refresh_token);

        return true;
      }

      // @ts-ignore
      if (error && error.status >= 400 && error.status < 500) {
        api.dispatch(loggedOut());
      }

      return false;
    })
    .finally(() => {
      refreshTokenPromise = undefined;
    });

  return refreshTokenPromise;
}

export function reauth(baseQuery: BaseQueryFn, authBaseQuery?: BaseQueryFn): BaseQueryFn {
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    // @ts-ignore
    if (result.error?.status === 401) {
      const canBeRetry = await refreshToken(authBaseQuery || defaultAuthBaseQuery, api);

      if (canBeRetry) {
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      }
    }

    return result;
  };
}
