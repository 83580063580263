import {
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  ToolbarButton,
  ToolbarButtonProps,
  Tooltip,
} from '@fluentui/react-components';
import { bundleIcon, PinOffFilled, PinOffRegular } from '@fluentui/react-icons';
import { useMemo } from 'react';
import { GroupValues } from '../form';

const PinOffIcon = bundleIcon(PinOffFilled, PinOffRegular);

export type GroupSelectEventHandler = (groupId: GroupValues['id'] | null) => void;

export type MoveToToolbarButtonProps = ToolbarButtonProps & {
  groups: GroupValues[];
  onGroupSelect: GroupSelectEventHandler;
};

export const MoveToToolbarButton = ({ groups, onGroupSelect, ...rest }: MoveToToolbarButtonProps) => {
  const sortedGroups = useMemo(() => {
    return groups.sort((a, b) => a.name.localeCompare(b.name));
  }, [groups]);

  return (
    <Menu>
      <Tooltip content="Переместить выбранные кампании в группу..." relationship="description" withArrow>
        <MenuTrigger disableButtonEnhancement>
          <ToolbarButton {...rest} />
        </MenuTrigger>
      </Tooltip>
      <MenuPopover>
        <MenuList>
          <MenuItem
            icon={<PinOffIcon />}
            onClick={() => {
              onGroupSelect(null);
            }}
          >
            Удалить привязку к группе
          </MenuItem>
          {sortedGroups.length > 0 && (
            <>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>Выберите группу:</MenuGroupHeader>
                {sortedGroups.map((group) => (
                  <MenuItem
                    key={group.id}
                    onClick={() => {
                      onGroupSelect(group.id);
                    }}
                  >
                    {group.name}
                  </MenuItem>
                ))}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default MoveToToolbarButton;
