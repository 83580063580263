import {
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridProps,
  DataGridRow,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  makeStyles,
  mergeClasses,
  PresenceBadge,
  shorthands,
  Switch,
  SwitchProps,
  TableCellLayout,
  TableColumnDefinition,
  TableRowId,
  tokens,
} from '@fluentui/react-components';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import { TableSelectionCellProps } from '@fluentui/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Campaign, translateCampaignState } from '../../../../app/services/optimizer';
import EmptyState from '../../../../components/EmptyState';
import SearchInput, { InputChangeEventHandler } from '../../../../components/SearchInput';
import { GroupValues } from '../../form';
import { CompatibilityEnum, getCompatibilityDescription, getCompatibilityStatus } from '../data';
import GroupDropdown from '../GroupDropdown';
import { applyFilter, useFilter } from './filter';

type SwitchChangeEventHandler = NonNullable<SwitchProps['onChange']>;
type SelectionChangeEventHandler = NonNullable<DataGridProps['onSelectionChange']>;

export type CampaignAddEventHandler = (campaigns: Campaign[], group?: GroupValues) => void;

export type AddCampaignsDialogProps = {
  campaigns: Campaign[];
  groups: GroupValues[];
  onAdd: CampaignAddEventHandler;
  onClose: () => void;
};

type CampaignItem = Campaign & {
  compatibility: CompatibilityEnum;
  selectionCell: TableSelectionCellProps;
};

const columns: TableColumnDefinition<CampaignItem>[] = [
  createTableColumn({
    columnId: 'id',
    compare: (a, b) => a.id - b.id,
    renderHeaderCell: () => 'ID',
    renderCell: (item) => item.id,
  }),
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => a.name.localeCompare(b.name),
    renderHeaderCell: () => 'Название',
    renderCell: (item) => item.name,
  }),
  createTableColumn({
    columnId: 'status',
    compare: (a, b) => a.state.localeCompare(b.state),
    renderHeaderCell: () => 'Статус',
    renderCell: (item) => translateCampaignState(item.state),
  }),
  createTableColumn({
    columnId: 'compatibility',
    compare: (a, b) => {
      if (!a.compatibility || !b.compatibility) {
        return 0;
      }

      return a.compatibility - b.compatibility;
    },
    renderHeaderCell: () => 'Совместимость',
    renderCell: (item) => (
      <TableCellLayout
        media={
          <PresenceBadge
            outOfOffice
            status={item.compatibility === CompatibilityEnum.Compatible ? 'available' : 'blocked'}
          />
        }
        description={getCompatibilityDescription(item.compatibility)}
      >
        {item.compatibility === CompatibilityEnum.Compatible ? 'совместима' : 'не совместима'}
      </TableCellLayout>
    ),
  }),
];
const getRowId = (item: Campaign) => item.id;

const defaultSelectedRowsState = () => new Set<TableRowId>();

const useStyles = makeStyles({
  dialogWidth: {
    maxWidth: '1400px',
  },
  groupField: {
    maxWidth: '600px',
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.gap(tokens.spacingHorizontalM),
    marginTop: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
  },
  filterQuery: {
    flexGrow: 0.5,
  },
  cellId: {
    width: '100px',
    maxWidth: '100px',
  },
  cellStatus: {
    width: '120px',
    maxWidth: '120px',
  },
});

export const AddCampaignsDialog = ({ campaigns, groups, onAdd, onClose }: AddCampaignsDialogProps) => {
  const [selectedGroup, setSelectedGroup] = useState<GroupValues | undefined>();
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRowsState);

  const handleOpenChange = useCallback<DialogOpenChangeEventHandler>(
    (ev, data) => {
      if (!data.open) {
        onClose();
      }
    },
    [onClose]
  );

  const [filter, filterMethods] = useFilter();
  const handleQueryChange = useCallback<InputChangeEventHandler>(
    (ev, data) => {
      filterMethods.setQuery(data.value);

      if (selectedRows.size > 0) {
        setSelectedRows(defaultSelectedRowsState);
      }
    },
    [filterMethods, selectedRows, setSelectedRows]
  );
  const handleShowAllChange = useCallback<SwitchChangeEventHandler>(
    (ev, data) => {
      filterMethods.setShowAll(data.checked);

      if (selectedRows.size > 0) {
        setSelectedRows(defaultSelectedRowsState);
      }
    },
    [filterMethods, selectedRows, setSelectedRows]
  );

  const selectableIds = useMemo<Set<TableRowId>>(
    () => new Set(campaigns.filter((campaign) => campaign.selectable).map((campaign) => campaign.id)),
    [campaigns]
  );
  const handleSelectionChange = useCallback<SelectionChangeEventHandler>(
    (ev, { selectedItems }) => {
      const intersection = new Set<TableRowId>();

      for (const id of selectedItems) {
        if (selectableIds.has(id)) {
          intersection.add(id);
        }
      }

      setSelectedRows(intersection);
    },
    [setSelectedRows, selectableIds]
  );

  const handleAddClick = useCallback(() => {
    const selectedCampaigns: Campaign[] = [];

    for (const id of selectedRows) {
      const campaign = campaigns.find((campaign) => campaign.id === id);

      if (campaign) {
        selectedCampaigns.push(campaign);
      }
    }

    onAdd(selectedCampaigns, selectedGroup);
  }, [campaigns, selectedGroup, selectedRows, onAdd]);

  const filteredCampaigns = useMemo<CampaignItem[]>(
    () =>
      applyFilter(campaigns, filter).map(
        (campaign): CampaignItem => ({
          ...campaign,
          compatibility: getCompatibilityStatus(campaign),
          selectionCell: {
            hidden: !campaign.selectable,
          },
        })
      ),
    [campaigns, filter]
  );

  const classes = useStyles();

  return (
    <Dialog open={true} modalType="modal" onOpenChange={handleOpenChange}>
      <DialogSurface className={classes.dialogWidth}>
        <DialogBody className={classes.dialogWidth}>
          <DialogTitle>Добавление кампаний</DialogTitle>
          <DialogContent>
            <Field className={classes.groupField} label="Группа">
              <GroupDropdown
                groups={groups}
                selectedGroup={selectedGroup}
                placeholder="Без группы"
                onSelect={setSelectedGroup}
              />
            </Field>
            <div className={classes.filter}>
              <SearchInput
                className={classes.filterQuery}
                placeholder="Поиск по списку"
                value={filter.query}
                onChange={handleQueryChange}
              />
              <Switch label="Показать все" checked={filter.showAll} onChange={handleShowAllChange} />
            </div>
            {filteredCampaigns.length === 0 && filteredCampaigns.length !== campaigns.length && (
              <EmptyState
                primaryMessage="Не найдено ни одной кампании"
                secondaryMessage="Попробуйте изменить настройки фильтра."
              />
            )}
            {filteredCampaigns.length === 0 && campaigns.length === 0 && (
              <EmptyState primaryMessage="Не найдено ни одной кампании для выбора" />
            )}
            {filteredCampaigns.length > 0 && (
              <DataGrid
                columns={columns}
                items={filteredCampaigns}
                getRowId={getRowId}
                sortable
                selectionMode="multiselect"
                subtleSelection
                selectedItems={selectedRows}
                onSelectionChange={handleSelectionChange}
              >
                <DataGridHeader>
                  <DataGridRow selectionCell={{ 'aria-label': 'Выбор всех строк' }}>
                    {({ renderHeaderCell, columnId }) => (
                      <DataGridHeaderCell
                        className={mergeClasses(
                          columnId === 'id' && classes.cellId,
                          columnId === 'status' && classes.cellStatus
                        )}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    )}
                  </DataGridRow>
                </DataGridHeader>
                <DataGridBody<CampaignItem>>
                  {({ item, rowId }) => (
                    <DataGridRow<CampaignItem>
                      key={rowId}
                      selectionCell={item.selectionCell}
                      data-testid={`data-grid-row-${rowId}`}
                    >
                      {({ renderCell, columnId }) => (
                        <DataGridCell
                          className={mergeClasses(
                            columnId === 'id' && classes.cellId,
                            columnId === 'status' && classes.cellStatus
                          )}
                        >
                          {renderCell(item)}
                        </DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </DataGrid>
            )}
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={handleAddClick} disabled={selectedRows.size === 0}>
              Добавить
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Отмена</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AddCampaignsDialog;
