import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../app/services/auth';
import type { RootState } from '../../app/store';

export enum AuthenticateStatus {
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
}

type AuthState = {
  status: AuthenticateStatus;
};

const slice = createSlice({
  name: 'auth',
  initialState: { status: AuthenticateStatus.unauthenticated } as AuthState,
  reducers: {
    loggedIn: (state) => {
      state.status = AuthenticateStatus.authenticated;
    },
    loggedOut: (state) => {
      state.status = AuthenticateStatus.unauthenticated;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state) => {
      state.status = AuthenticateStatus.authenticated;
    });
  },
});

export default slice.reducer;
export const { loggedIn, loggedOut } = slice.actions;
export const isAuthenticated = (state: RootState) => state.auth.status === AuthenticateStatus.authenticated;
