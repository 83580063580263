import { makeStyles, tokens } from '@fluentui/react-components';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useGetProjectSettingsQuery } from '../../app/services/optimizer';
import { isApiError } from '../../app/utils/error';
import { NotFound } from '../../components/error';
import FetchError from '../../components/FetchError';
import { Loader } from '../../components/Loader';
import SettingsForm from '../../features/settings/SettingsForm';
import SuspendSwitch from '../../features/settings/SuspendSwitch';
import { ProjectParamKeys } from '../../router';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: '100%',
  },
  suspendSwitch: {
    marginTop: '15px',
    marginLeft: tokens.spacingHorizontalS,
    marginRight: tokens.spacingHorizontalXXS,
  },
  form: {
    flexGrow: 1,
  },
});

export const Settings = () => {
  const { project } = useParams<ProjectParamKeys>();
  const { data: response, error, isLoading } = useGetProjectSettingsQuery(project ? { project } : skipToken);
  const classes = useStyles();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    if (isApiError(error)) {
      return <FetchError error={error} />;
    }

    throw error;
  }

  if (!response) {
    return <NotFound />;
  }

  return (
    <div className={classes.root}>
      <SuspendSwitch className={classes.suspendSwitch} settings={response.data} />
      <SettingsForm className={classes.form} settings={response.data} />
    </div>
  );
};

export default Settings;
