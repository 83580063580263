import { Listbox, makeStyles, mergeClasses, resolveShorthand, Slot } from '@fluentui/react-components';

const useStyles = makeStyles({
  listbox: {
    maxHeight: '50vh',
  },
});

export const useListboxStyles = (slot?: Slot<typeof Listbox>): Slot<typeof Listbox> => {
  const classes = useStyles();

  const result: Slot<typeof Listbox> = resolveShorthand(slot) || {};
  result.className = mergeClasses(classes.listbox, result.className);

  return result;
};
